import SelectPatient from "../../Components/Orders/SelectPatient";
import NewOrder from "../../Components/Orders/NewOrder";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { retrievePatientDetail } from "../../Api/Patients/PatientsFetch";
import SearchPatient from "../../Components/Orders/SearchPatient";

const OrderAdd = () => {
  const jwt = localStorage.getItem("jwt");
  const sanctum = localStorage.getItem("sanctum");
  const [patientDetail, setPatientDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      retrievePatientDetail({
        jwt,
        sanctum,
        setPatientDetail,
        id,
        setIsLoading,
      });
      console.log(patientDetail);
    } else {
      setPatientDetail("");
    }
  }, [id]);
  console.log(patientDetail);

  return (
    <>
      <Row className={"mt-4 mx-0"}>
        {patientDetail ? (
          <>
            <SelectPatient patientDetail={patientDetail}></SelectPatient>
            <NewOrder
              patientId={patientDetail.id}
              payerId={patientDetail.payer_id}
            />
          </>
        ) : (
          <SearchPatient
            setPatientDetail={setPatientDetail}
            patientDetail={patientDetail}
          ></SearchPatient>
        )}
      </Row>
    </>
  );
};

export default OrderAdd;
