import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "../../assets/css/Patients/Patients.css";
import { emptyError, handleErrors } from "../Errors";
import { downloadPods } from "../../Api/POD/PodFetch";
import { TailSpin } from "react-loader-spinner";

const FormDownload = () => {
  const [message, setMessage] = useState();
  const [errors, setErrors] = useState();
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    if (errors) {
      console.log("errors:", errors);
      handleErrors(errors);
    }
  }, [errors]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = document.getElementById("podForm");
    const formData = new FormData(form);

    emptyError();
    const emptyfields = document.querySelector("span.error_empty_field");
    if (!emptyfields) {
      downloadPods(formData, { setIsLoading, setMessage, setErrors });
    }
  };

  return (
    <>
      {message && (
        <Alert
          variant={"success"}
          onClick={() => {
            setMessage(null);
          }}
          dismissible
        >
          {" "}
          {message}{" "}
        </Alert>
      )}
      {errors && typeof errors === "string" && (
        <Alert
          variant={"danger"}
          onClick={() => {
            setErrors(null);
          }}
          dismissible
        >
          {" "}
          {errors}{" "}
        </Alert>
      )}

      <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0 mt-5"}>
        <Col
          className={
            "bkg-primary text-white text-start rounded-top-3 align-items-center"
          }
        >
          <Row>
            <Col md={8}>
              <p className={"mb-0 py-2"}>Carica File</p>
            </Col>
            <Col
              md={4}
              className={
                "d-flex justify-content-end align-items-center position-relative"
              }
            ></Col>
          </Row>
        </Col>

        <Form
          className={"row py-3 text-start "}
          id={"podForm"}
          encType={"multipart/form-data"}
        >
          <Row className={"align-items-center d-flex"}>
            <Col md={9}>
              <Row className={"align-items-center d-flex"}>
                <Form.Label className={"col-md-2"}>
                  Carica File Excel
                </Form.Label>
                <Col className={"col-md-10 "}>
                  <Form.Control
                    type="file"
                    accept=".xlsx, .xls"
                    className={"form-control-sm"}
                    name={"file"}
                    required
                  />
                </Col>
              </Row>
            </Col>

            <Col md={3} className={"text-end"}>
              <Button
                variant="primary"
                type="submit"
                className={
                  "rounded-5 btn-sm px-5 w-100 mt-0 btn text-uppercase"
                }
                onClick={handleSubmit}
              >
                Invia
              </Button>
            </Col>
          </Row>
        </Form>

        {loading && (
          <TailSpin
            height="80"
            width="80"
            color="var(--primary)"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
            visible={true}
          />
        )}
      </Row>
    </>
  );
};

export default FormDownload;
