import axiosInstance from "../AxiosInstance";
import { getFilenameFromContentDisposition } from "../../Utils/GetHeaderFileName";

//retrieve dispensation list
export const retrieveDispensationsList = ({
  setDispensationsList,
  pageUrl,
  search,
  perPage,
  filterSort,
  setIsLoading,
  setTotalRecords,
  setPagination,
}) => {
  setIsLoading(true);
  const symbol = pageUrl.includes("?") ? "&" : "?";

  let url = pageUrl + symbol + perPage;

  const query = search
    ? Object.keys(search)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(search[key]),
        )
        .join("&")
    : null;

  const filter = filterSort
    ? Object.keys(filterSort)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filterSort[key]),
        )
        .join("&")
    : null;

  if (query !== null) url += "&" + query;
  if (filter !== null) url += "&" + filter;

  console.log(url);

  axiosInstance
    .get(url)
    .then((response) => {
      // Handle successful response (status code 200)
      setTotalRecords(response.data.data.total);
      setDispensationsList(response.data.data.data);
      setPagination(response.data.data.links);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        // setErrors(error.response.data.message)
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        // setErrors(error)
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//update shipping at for one or more dispensations
export const updateShippingAt = (
  dispensation_ids,
  shipping_at,
  { setMessage, setErrors, setIsLoading },
) => {
  setIsLoading(true);
  axiosInstance
    .post("/api/dispensations/v1/update-shipping-at", {
      dispensation_ids: dispensation_ids,
      shipping_at: shipping_at,
    })
    .then((response) => {
      setMessage(response.data.message);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.errors.shipping_at);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//download POD
export const downloadDispensationPod = (
  dispensation_id,
  type,
  { setMessage, setErrors, setIsLoading },
) => {
  setIsLoading(true);

  fetch(
    process.env.REACT_APP_BASE_URL +
      "/api/dispensations/v1/" +
      dispensation_id +
      "/" +
      type +
      "/download",
    {
      method: "GET",
      responseType: "blob", // Important,
      headers: {
        "X-JWT-Token": localStorage.getItem("jwt"),
        Authorization: "Bearer " + localStorage.getItem("sanctum"),
      },
    },
  )
    .then((res) => {
      const filename = getFilenameFromContentDisposition(res.headers);
      return res.blob().then((blob) => ({ filename, blob }));
    })
    .then(
      ({ filename, blob }) => {
        const outputFilename = filename || `download_${type}.pdf`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {
          type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link); // Remove the link after download
        }, 100);

        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setErrors("Errore download " + type);
      },
    );
};

export const retrievePlannedList = ({
  setMassiveDispensationList,
  pageUrl,
  search,
  perPage,
  filterSort,
  setIsLoading,
  setTotalRecords,
  setPagination,
}) => {
  setIsLoading(true);
  const symbol = pageUrl.includes("?") ? "&" : "?";

  let url = pageUrl + symbol + perPage;

  const query = search
    ? Object.keys(search)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(search[key]),
        )
        .join("&")
    : null;

  const filter = filterSort
    ? Object.keys(filterSort)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filterSort[key]),
        )
        .join("&")
    : null;

  if (query !== null) url += "&" + query;
  if (filter !== null) url += "&" + filter;
  console.log(url);
  axiosInstance
    .get(url)
    .then((response) => {
      // Handle successful response (status code 200)
      console.log(response.data.data);
      setTotalRecords(response.data.data.total);
      setMassiveDispensationList(response.data.data.data);
      setPagination(response.data.data.links);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        // setErrors(error.response.data.message)
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        // setErrors(error)
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//toggle dispensation status
export const toggleDispensationStatus = (
  dispensation_id,
  reason_id,
  { setMessage, setErrors, setIsLoading },
) => {
  setIsLoading(true);
  axiosInstance
    .post("/api/dispensations/v1/toggle-status", {
      dispensation_id: dispensation_id,
      reason_id: reason_id,
    })
    .then((response) => {
      setMessage(response.data.message);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//list of suspended reason fo dispensation
export const retriveReasonSuspended = ({ setReasons }) => {
  axiosInstance
    .get("/api/dispensations/v1/suspension-reasons")
    .then((response) => {
      setReasons(response.data.data);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        // setErrors(error.response.data.message)
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        // setErrors(error)
        console.error("Error:", error);
      }
    });
};

//planned list of dispensation
export const downloadPlannedDispensations = ({
  setMessage,
  setErrors,
  setIsLoading,
}) => {
  setIsLoading(true);

  fetch(
    process.env.REACT_APP_BASE_URL + "/api/dispensations/v1/planned/export",
    {
      method: "GET",
      responseType: "blob", // Important,
      headers: {
        "X-JWT-Token": localStorage.getItem("jwt"),
        Authorization: "Bearer " + localStorage.getItem("sanctum"),
      },
    },
  )
    .then((res) => {
      console.log(res);
      return res.blob();
    })
    .then(
      (blob) => {
        const outputFilename = `export_dispensazioni_pianificate.xlsx`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        // setErrors("Errore importazione. Contatta l'amministratore!")
      },
    );
};

//export list of dispensation in excel
export const exportDispensationList = (
  formData,
  { setIsLoading, setErrors, setMessage },
) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/dispensations/v1/export/redirect", formData)
    .then((response) => {
      window.location.href = response.data.data.url;
      setMessage(response.data.message);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.errors);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        console.log(error);
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//get tracking list for specific dispensation
export const getTrackingList = (
  dispensation_id,
  { setIsLoading, setErrors, setTrackingList },
) => {
  setIsLoading(true);
  axiosInstance
    .get("/api/dispensations/v1/" + dispensation_id + "/track")
    .then((response) => {
      setTrackingList(response.data.data);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//update forniture of strips and lancets on a dispensation
export const updateFurnitureDispensation = (
  dispensation_id,
  data,
  { setMessage, setErrors, setIsLoading },
) => {
  setErrors(undefined);
  setMessage(undefined);
  setIsLoading(true);

  axiosInstance
    .post(
      "/api/dispensations/v1/" + dispensation_id + "/update_fornitures",
      data,
    )
    .then((response) => {
      setMessage(response.data.message);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

//force resend dispensation with increment id and check ticket code
export const reprocessDispensation = async (
  dispensationId,
  { setIsLoading, setMessage, setErrors },
) => {
  setIsLoading(true);
  try {
    const response = await axiosInstance.get(
      "/api/dispensations/v1/reprocess/" + dispensationId,
    );
    if (response.status === 200) {
      setMessage("Dispensazione riprocessata con successo!");
      setIsLoading(false);
      return response.data;
    }
  } catch (error) {
    if (error.response && error.response.status === 422) {
      // Handle the 422 response (validation error)
      setErrors(error.response.data.message);
      setIsLoading(false);
      console.log("Validation Errors:", error.response.data.message);
    } else {
      // Handle other errors (e.g., network issues)
      setErrors(
        error.response.data.message ?? "Errore riprocessamento dispensazione!",
      );
      setIsLoading(false);
      console.error("Error:", error);
    }
  }
};
