import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Patients/Patients.css";
import { BiEraser } from "react-icons/bi";
import IconTooltip from "../Layout/IconsTooltip";
import { retrievePayers } from "../../Api/Payer/PayersFetch";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import { Grouped } from "../../Utils/Grouped";
import authContext from "../../Contexts/authContext";
import { Role } from "../../Utils/Role";

const SearchPatientsForm = (props) => {
  const [payersData, setPayersData] = useState();
  const [groupedPayers, setGroupedPayers] = useState();
  const [errors, setErrors] = useState();
  const { isMultipayer } = useContext(authContext);
  const [role, setRole] = useState();

  useEffect(() => {
    const actualRole = Role();
    setRole(actualRole);
  }, []);

  //get payers
  useEffect(() => {
    if (isMultipayer) {
      retrievePayers({ setPayersData, setErrors });
    }
  }, [isMultipayer]);

  //grouped payer
  useEffect(() => {
    setGroupedPayers(Grouped(payersData, "region"));
  }, [payersData]);

  const clearInput = () => {
    props.setSearch({});
    for (let i of document.querySelectorAll("form input, form select")) {
      i.value = "";
    }
  };
  const handleSearch = (e) => {
    clearInputNameRandomString();

    props.setSearch({});
    props.setPageUrl("/api/patients/v1?page=1");
    e.preventDefault();

    for (let i of document.querySelectorAll("form input, form select")) {
      if (i.value !== "") {
        const name = i.getAttribute("name");
        let value = i.value;

        if (process.env.REACT_APP_ENVIRONMENT === "staging") {
          value = i.value > 1000000 ? i.value - 1000000 : i.value;
        }

        props.setSearch((search) => ({
          ...search,
          [name]: value,
        }));
      }
    }

    generateRandomStringToAddInputName();
  };
  return (
    <>
      <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0"}>
        <Col
          className={
            "bkg-primary text-white text-start rounded-top-3 align-items-center"
          }
        >
          <Row>
            <Col md={8}>
              <p className={"mb-0 py-2"}>Ricerca paziente</p>
            </Col>
            <Col
              md={4}
              className={
                "d-flex justify-content-end align-items-center position-relative"
              }
            >
              <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                <BiEraser className={"fs-3 text-second d-block"}></BiEraser>
              </IconTooltip>
            </Col>
          </Row>
        </Col>

        <Form className={"row py-3 text-start"} id={"searchForm"}>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Id Paziente</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="text"
                  name={"filters[id]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Cognome</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="text"
                  name={"filters[last_name]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Nome</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="text"
                  name={"filters[first_name]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Codice Fiscale</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="text"
                  name={"filters[cf]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Telefono</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="text"
                  name={"filters[phone_number]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Email</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Control
                  type="email"
                  name={"filters[email]"}
                  className={"d-inline form-control-sm"}
                />
              </Col>
            </Row>
          </Form.Group>
          {!!role &&
            !["data_entry", "single_user", "referente_aziendale"].includes(
              role,
            ) && (
              <Form.Group className={"col-md-4 my-2"}>
                <Row>
                  <Form.Label className={"col-md-3"}>Regione</Form.Label>
                  <Col className={"col-md-8 "}>
                    <Form.Select
                      name={"filters[payer.region]"}
                      className={"d-inline form-select-sm"}
                    >
                      <option readOnly={"readonly"} value={""}>
                        Seleziona
                      </option>
                      {groupedPayers &&
                        Object.keys(groupedPayers).map((region) => {
                          return (
                            <option key={region} value={region}>
                              {region}
                            </option>
                          );
                        })}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            )}
          {isMultipayer && (
            <Form.Group className={"col-md-4 my-2"}>
              <Row>
                <Form.Label className={"col-md-3"}>ASL</Form.Label>
                <Col className={"col-md-8 "}>
                  <Form.Select
                    name={"filters[payer_id]"}
                    className={"d-inline form-select-sm"}
                  >
                    <option readOnly={"readonly"} value={""}>
                      Seleziona
                    </option>
                    {groupedPayers &&
                      Object.keys(groupedPayers).map((region) => {
                        return (
                          <optgroup key={region} label={region}>
                            {groupedPayers[region]?.map(
                              (
                                payer, // Using optional chaining to handle undefined or null
                              ) => (
                                <option key={payer.id} value={payer.id}>
                                  {payer.title}
                                </option>
                              ),
                            )}
                          </optgroup>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          )}
          <Form.Group className={"col-md-4 my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Attivo</Form.Label>
              <Col className={"col-md-8 "}>
                <Form.Select
                  name={"filters[active]"}
                  className={"d-inline form-select-sm"}
                >
                  <option readOnly={"readonly"} value={""}>
                    Tutti
                  </option>
                  <option value={1}>Si</option>
                  <option value={0}>No</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
          <Row className={"text-center justify-content-center"}>
            <Col className={"col-md-3"}>
              <Button
                variant="primary"
                onClick={handleSearch}
                type="submit"
                className={"mt-3 rounded-5 px-5  text-uppercase"}
              >
                Cerca
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default SearchPatientsForm;
