import { Button, Col, Form, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import "../../assets/css/Patients/Patients.css";
import { BiEraser } from "react-icons/bi";
import IconTooltip from "../Layout/IconsTooltip";
import authContext from "../../Contexts/authContext";
import { retrievePayers } from "../../Api/Payer/PayersFetch";
import { Grouped } from "../../Utils/Grouped";

const SearchPlannedDispensations = (props) => {
  const { isMultipayer } = useContext(authContext);
  const [payersData, setPayersData] = useState();
  const [groupedPayers, setGroupedPayers] = useState();
  const [errors, setErrors] = useState();

  //get payers
  useEffect(() => {
    if (isMultipayer) {
      retrievePayers({ setPayersData, setErrors });
    }
  }, [isMultipayer]);

  //grouped payer
  useEffect(() => {
    setGroupedPayers(Grouped(payersData, "region"));
  }, [payersData]);

  const clearInput = () => {
    props.setSearch({});
    for (let i of document.querySelectorAll("form input, form select")) {
      i.value = "";
    }
  };

  const handleSearch = (e) => {
    props.setSearch({});

    e.preventDefault();
    console.log(props.search);
    for (let i of document.querySelectorAll("input:not([type=checkbox])")) {
      if (i.value !== "") {
        const name = i.getAttribute("name");
        const value = i.value;
        props.setSearch((search) => ({
          ...search,
          [name]: value,
        }));
      }
    }
    for (let i of document.querySelectorAll("form select")) {
      if (i.value !== "") {
        const name = i.getAttribute("name");
        const value = i.value;
        props.setSearch((search) => ({
          ...search,
          [name]: value,
        }));
      }
    }
  };

  return (
    <>
      <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0"}>
        <Col
          className={
            "bkg-primary text-white text-start rounded-top-3 align-items-center"
          }
        >
          <Row>
            <Col md={8}>
              <p className={"mb-0 py-2"}>Ricerca Dispensazioni Programmate</p>
            </Col>
            <Col
              md={4}
              className={
                "d-flex justify-content-end align-items-center position-relative"
              }
            >
              <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                <BiEraser className={"fs-3 text-second d-block"}></BiEraser>
              </IconTooltip>
            </Col>
          </Row>
        </Col>

        <Form className={"row py-3 text-start"} id={"searchForm"}>
          {isMultipayer && (
            <Form.Group className={"col-md-6 my-2"}>
              <Row>
                <Form.Label className={"col-md-3"}>
                  Asl di appartenenza
                </Form.Label>
                <Col className={"col-md-9 "}>
                  <Form.Select
                    id="payerId"
                    name={"filters[payer_id]"}
                    className={"d-inline form-select-sm"}
                  >
                    <option readOnly={"readonly"} value={""}>
                      Tutte
                    </option>
                    {groupedPayers &&
                      Object.keys(groupedPayers).map((region) => {
                        return (
                          <optgroup key={region} label={region}>
                            {groupedPayers[region]?.map(
                              (
                                payer, // Using optional chaining to handle undefined or null
                              ) => (
                                <option key={payer.id} value={payer.id}>
                                  {payer.title}
                                </option>
                              ),
                            )}
                          </optgroup>
                        );
                      })}
                  </Form.Select>
                </Col>
              </Row>
            </Form.Group>
          )}
          <Form.Group
            className={isMultipayer ? "col-md-6 my-2" : "col-md-12 my-2"}
          >
            <Row>
              <Form.Label className={"col-md-1"}>Mesi</Form.Label>
              <Col className={"col-md-6 "}>
                <Form.Select
                  id="nextMonths"
                  name={"filters[nextMonths]"}
                  className={"d-inline form-select-sm"}
                  defaultValue={1}
                >
                  <option value={1}>Mese Corrente</option>
                  <option value={3}>Prossimi 3 Mesi</option>
                  <option value={6}>Prossimi 6 Mesi</option>
                  <option value={12}>Prossimi 12 Mesi</option>
                  <option value={24}>Prossimi 24 Mesi</option>
                  <option value={""}>Tutte</option>
                </Form.Select>
              </Col>

              <Form.Label className={"col-md-2"}>Tipo Sensore</Form.Label>
              <Col className={"col-md-3 "}>
                <Form.Select
                  id="sensor_type"
                  name={"filters[order.sensor_type]"}
                  className={"d-inline form-select-sm"}
                >
                  <option value={""}>Tutti</option>
                  <option value={1}>FSL1</option>
                  <option value={2}>FSL2</option>
                  <option value={3}>FSL3</option>
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>

          <Row className={"text-center justify-content-center"}>
            <Col className={"col-md-3"}>
              <Button
                variant="primary"
                onClick={handleSearch}
                type="submit"
                className={"mt-3 rounded-5 px-5  text-uppercase"}
              >
                Cerca
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default SearchPlannedDispensations;
