import { Alert, Button, Col, Row } from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import OrderDetailsForm from "../../Components/Orders/DetailForm";
import {
  exportOrderDetail,
  exportOrderList,
  retrieveOrderDetail,
} from "../../Api/Orders/OrdersFetch";
import {
  downloadDispensationPod,
  reprocessDispensation,
  retriveReasonSuspended,
  toggleDispensationStatus,
  updateFurnitureDispensation,
  updateShippingAt,
} from "../../Api/Dispensations/DispensationsFetch";
import { formatId } from "../../Utils/FormatId";
import TrackingModal from "../../Components/Modal/TrackingModal";
import { ConfirmationModal } from "../../Components/Modal/ConfirmationModal";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const OrdersDetails = () => {
  const { id } = useParams();
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [orderDetail, setOrderDetail] = useState([]);
  const [pageUrl, setPageUrl] = useState("/api/orders/v1/");
  const [perPage, setPerPage] = useState("per_page=10");
  const [pagination, setPagination] = useState({});
  const [filterSort, setFilterSort] = useState("");
  const [records, setTotalRecords] = useState();
  const [errors, setErrors] = useState();
  const [updateView, setUpdateView] = useState(false);
  const [showReason, setShowReason] = useState(false);

  const [reasons, setReasons] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModalTracker, setShowModalTracker] = useState(false);
  const [trackerDispensationId, setTrackerDispensationId] = useState(null);
  const [edit, setEdit] = useState({ edit: false, action: "nothing", id: "0" });
  const [eventTarget, setEventTarget] = useState(null);
  const [actionMessage, setActionMessage] = useState(
    "Sei sicuro di voler effettuare questa azione?",
  );
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();

  //control close of modal
  const handleCloseTracker = () => {
    setShowModalTracker(false);
  };

  //control close of modal
  const handleClose = async () => {
    await setShowModal(false);
    await setActionMessage("");
  };

  //control show of modal and save event target
  const handleShow = (event) => {
    setShowModal(true);

    setEventTarget(event.currentTarget);
    setActionMessage(
      "Sicuro di voler riprocessare la dispensazione? La dispensazione sarà riprocessata e non sarà possibile annullare l'operazione.",
    );
  };

  //control action of modal
  const handleAction = async () => {
    if (eventTarget) {
      await reprocessDispensation(eventTarget.getAttribute("data-id"), {
        setMessage,
        setErrors,
        setIsLoading,
      });
    }

    await setShowModal(false);
    await setUpdateView(true);
  };

  useEffect(() => {
    retrieveOrderDetail({
      setOrderDetail,
      setIsLoading,
      pageUrl,
      id,
      setErrors,
    });
    retriveReasonSuspended({ setReasons });
    setUpdateView(false);
  }, [id, pageUrl, updateView]);

  const handleExportTable = () => {
    clearInputNameRandomString();
    const form = document.getElementById("searchForm");
    const formData = new FormData(form);

    exportOrderList(formData, { setIsLoading, setErrors, setMessage });
    generateRandomStringToAddInputName();
  };

  //download pod for specific dispensation
  const handleDownloadPod = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const type = e.currentTarget.getAttribute("data-type");

    if (id !== null) {
      downloadDispensationPod(id, type, {
        setMessage,
        setErrors,
        setIsLoading,
      });
    }
  };

  // Function change data
  const handleEditData = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const dataAction = e.currentTarget.getAttribute("data-action");
    let dataInput, dataDisabled;
    let action = false;
    let value = {};

    //check action of edit in row dispensation
    if (dataAction === "reschedule") {
      dataInput = document.querySelectorAll(
        'div[data-id="' + id + '"] input[name^="datepicker"]',
      );
      dataDisabled = document.querySelectorAll(
        'input[name^="strips_quantity"], input[name^="lancets_quantity"], div[data-id]:not([data-id="' +
          id +
          '"]) input[name^="datepicker"]',
      );
    } else {
      dataInput = document.querySelectorAll(
        'input[data-id="' +
          id +
          '"][name^="strips_quantity"], input[data-id="' +
          id +
          '"][name^="lancets_quantity"]',
      );
      dataDisabled = document.querySelectorAll(
        'input[data-id]:not([data-id="' +
          id +
          '"][name^="strips_quantity"]):not([data-id="' +
          id +
          '"][name^="lancets_quantity"]), input[name^="datepicker"]',
      );
      setEdit({ edit: true, action: dataAction, id: id });
    }

    //if input not disabled get value and send in function for edit-dispensation action (notes: reschedule action send data on focus out)
    if (id != null) {
      dataInput.forEach((input) => {
        if (input.hasAttribute("disabled")) {
          dataDisabled.forEach((inputDisabled) =>
            inputDisabled.setAttribute("disabled", "disabled"),
          );
          input.removeAttribute("disabled");
        } else {
          input.setAttribute("disabled", "disabled");
          dataDisabled.forEach((inputDisabled) =>
            inputDisabled.setAttribute("disabled", "disabled"),
          );
          value[input.getAttribute("name")] = input.value;
          action = true;
        }
      });

      if (action === true && dataAction === "edit-dispensation") {
        updateFurnitureDispensation(id, value, {
          setMessage,
          setErrors,
          setIsLoading,
        });
        setEdit({ edit: false, action: "nothing", id: id });
      }
    }
  };

  //function to handle action (no modal)
  const handleSelectReason = (e) => {
    const dispensation_id = e.currentTarget.getAttribute("data-id");
    if (showReason == dispensation_id) {
      setShowReason(false);
    } else {
      setShowReason(dispensation_id);
    }
  };

  //function to handle dispensaction action
  const handleDispensationAction = (e) => {
    setShowReason(false);
    setUpdateView(false);

    const dispensation_id = e.currentTarget.getAttribute("data-id");
    const reason_id = document.getElementById("reason_id")
      ? document.getElementById("reason_id").value
      : null;

    if (dispensation_id != null) {
      setTimeout(() => {
        if (
          e.target.tagName.toLowerCase() === "input" ||
          e.target.tagName.toLowerCase() === "button"
        ) {
          //if changed data updated input
          const shipping_at = document.querySelector(
            'input[data-id="' + dispensation_id + '"][name^="shipping_at"]',
          ).value;
          updateShippingAt([dispensation_id], shipping_at, {
            setErrors,
            setMessage,
            setIsLoading,
          });
          document
            .querySelector('input[data-id="' + dispensation_id + '"]')
            .setAttribute("readonly", "readonly");
        } else {
          toggleDispensationStatus(dispensation_id, reason_id, {
            setErrors,
            setMessage,
            setIsLoading,
          });

          setTimeout(() => {
            setUpdateView(true);
            setEdit({ edit: false, action: "nothing", id: dispensation_id });
          }, 500);
        }
      }, 500);
    }
  };

  //export order detail
  const handleExportDetail = () => {
    exportOrderDetail(id, { setIsLoading, setErrors });
  };

  return (
    <>
      <ConfirmationModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleAction}
        actionMessage={actionMessage}
        isButtonDisabled={isButtonDisabled}
      />
      <Row className={"text-start mt-2"}>
        <Col md={6} className={"text-start"}>
          <h2>Dettaglio ordine - ID {formatId(parseInt(id))}</h2>
        </Col>
        <Col md={6} className={"text-end"}>
          {orderDetail && orderDetail.exportable === true && (
            <Button className={"btn btn-primary"} onClick={handleExportDetail}>
              Stampa Dettaglio
            </Button>
          )}
        </Col>
      </Row>
      <OrderDetailsForm
        id={id}
        orderDetail={orderDetail}
        setUpdateView={setUpdateView}
        setOrderDetail={setOrderDetail}
      ></OrderDetailsForm>
      <Row className={"text-start mb-2"}>
        <h2>Elenco dispensazioni</h2>
      </Row>

      {message && (
        <Alert
          variant={"success"}
          onClick={() => {
            setMessage(null);
          }}
          dismissible
        >
          {message}
        </Alert>
      )}
      {errors && (
        <Alert
          variant={"danger"}
          onClick={() => {
            setErrors(null);
          }}
          dismissible
        >
          {errors}
        </Alert>
      )}

      <CustomTable
        orderDetail={orderDetail}
        pagination={pagination}
        setPerPage={setPerPage}
        perPage={perPage}
        setPageUrl={setPageUrl}
        setFilterSort={setFilterSort}
        filterSort={filterSort}
        records={records}
        handleExportTable={handleExportTable}
        handleDownloadPod={handleDownloadPod}
        handleEditData={handleEditData}
        handleDispensationAction={handleDispensationAction}
        showReason={showReason}
        reasons={reasons}
        handleSelectReason={handleSelectReason}
        setShowModalTracker={setShowModalTracker}
        setTrackerDispensationId={setTrackerDispensationId}
        edit={edit}
        handleReprocessDispensation={handleShow}
      />

      <TrackingModal
        show={showModalTracker}
        handleClose={handleCloseTracker}
        dispensationId={trackerDispensationId}
      />

      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default OrdersDetails;
