import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import "../../assets/css/Orders/Orders.css";
import { emptyError, handleErrors } from "../Errors";
import { TailSpin } from "react-loader-spinner";
import { addOrder, editOrder } from "../../Api/Orders/OrdersFetch";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import { useNavigate } from "react-router-dom";
import { getPayerRules } from "../../Api/Payer/PayersFetch";
import { DateInput } from "../DateInput";
import { ConfirmationModal } from "../Modal/ConfirmationModal";
import { checkPendingOrder } from "../../Api/Patients/PatientsFetch";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const NewOrder = (props) => {
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
  const [durationMonths, setDurationMonths] = useState();
  const [errors, setErrors] = useState();
  const [loading, setIsLoading] = useState(false);
  const [message, setMessage] = useState();
  const [payerRules, setPayerRules] = useState();
  const [durationPlans, setDurationPlans] = useState([]);
  const [enableFornitures, setEnableFornitures] = useState([]);
  const [frequencyNames, setFrequencyNames] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [actionMessage, setActionMessage] = useState(
    "Sei sicuro di voler effettuare questa azione?",
  );
  const [hasPendingOrder, setHasPendingOrder] = useState(null);
  const navigate = useNavigate();

  //get payer rules
  useEffect(() => {
    generateRandomStringToAddInputName();

    if (props.payerId !== undefined) {
      getPayerRules(props.payerId, { setPayerRules, setErrors });
    }
  }, [props.payerId]);

  //set duration rules
  useEffect(() => {
    let sensorsPlan = payerRules ? JSON.parse(payerRules.sensors_plan) : null;
    let activeFornitures = payerRules
      ? JSON.parse(payerRules.enable_fornitures)
      : null;
    let namesFrequency = payerRules
      ? JSON.parse(payerRules.frequency_names)
      : null;
    let plans = [];

    if (sensorsPlan !== null) {
      Object.keys(sensorsPlan).map((key) => {
        return Object.keys(sensorsPlan[key]).map((key) => {
          if (!plans.includes(key)) plans.push(key);
        });
      });
    }

    setDurationPlans(plans);
    setEnableFornitures(activeFornitures);
    setFrequencyNames(namesFrequency);
  }, [payerRules]);

  //handle errors
  useEffect(() => {
    if (errors) {
      console.log("errors:", errors);
      handleErrors(errors);
    }
  }, [errors]);

  //check tipology change
  const handleTipologyChange = (e) => {
    //handle tipology change trigger on a check or a div if disabled
    console.log("e.currentTarget:", e.currentTarget);
    const action = e.currentTarget.value
      ? e.currentTarget.getAttribute("data-action")
      : e.currentTarget
          .querySelector('input[type="radio"]:checked')
          .getAttribute("data-action");

    const value =
      e.currentTarget.value ??
      e.currentTarget.querySelector('input[type="radio"]:checked').value;

    if (action === "stripe") {
      if (value === "0") {
        //Disable Stisce Quantities
        document
          .querySelectorAll("input[name^=quantity_stripes]")
          .forEach((i) => {
            i.checked = false;
            i.setAttribute("disabled", "disabled");
            i.removeAttribute("required");
          });

        //If it is only sensors, it removes the dispensing frequency checks with a negative value because they are connected to strips and lancets
        document
          .querySelectorAll("input[name^=dispensing_frequency]")
          .forEach((frequency) => {
            frequency.checked = false;

            if (frequency.value < 0) {
              frequency.setAttribute("disabled", "disabled");
              frequency.removeAttribute("required");
            } else {
              frequency.setAttribute("required", "required");
              frequency.removeAttribute("disabled");
            }
          });
      } else {
        //Rehabilitate strip quantities
        document
          .querySelectorAll(" input[name^=quantity_stripes]")
          .forEach((i) => {
            i.removeAttribute("disabled");
            i.setAttribute("required", "required");
          });

        //Re-enable dispensing frequencies with a negative value
        document
          .querySelectorAll("input[name^=dispensing_frequency]")
          .forEach((frequency) => {
            frequency.checked = false;
            frequency.setAttribute("required", "required");
            frequency.removeAttribute("disabled");
          });
      }
    }

    if (action === "sensor") {
      //check if sensor frequency names exist and disable sensors based on payer rules
      if (payerRules.sensors_frequency) {
        document
          .querySelectorAll("input[name^=dispensing_frequency]")
          .forEach((i) => {
            if (
              !JSON.parse(payerRules.sensors_frequency)[
                value.toString()
              ].includes(parseInt(i.value))
            ) {
              i.checked = false;
              i.setAttribute("disabled", "disabled");
            } else {
              i.removeAttribute("disabled");
            }
          });
      }
      //Disable strips and lancets for FSL3 according to specific field and check the reader selection
      if (value === "3") {
        const querySelector =
          payerRules.fsl3_disable_bgm === 1
            ? "input[name^=include_reader], input[name^=quantity_stripes]"
            : "input[name^=include_reader]";
        document.querySelectorAll(querySelector).forEach((i) => {
          i.checked = false;
          i.setAttribute("disabled", "disabled");
          i.removeAttribute("required");
        });

        //disabled "completa" option to forniture type if sensor is FSL3
        if (payerRules.fsl3_disable_bgm === 1) {
          document
            .querySelector(".forniture_type_1")
            ?.setAttribute("disabled", "disabled");
          if (
            document.querySelector("select[name=forniture_type]")
              .selectedIndex === 2
          )
            document.querySelector(
              "select[name=forniture_type]",
            ).selectedIndex = 1;
        }
      } else {
        document
          .querySelectorAll(" input[name^=include_reader]")
          .forEach((i) => {
            i.removeAttribute("disabled");
            i.setAttribute("required", "required");
          });

        //enabled "completa" option to forniture type if sensor is different FSL3
        if (payerRules.fsl3_disable_bgm === 1) {
          document
            .querySelector(".forniture_type_1")
            ?.removeAttribute("disabled");
        }
      }
    }

    if (action === "frequency") {
      let sensorForniture = document.querySelector(
        "select[name=forniture_type]",
      ).value;
      //check if frequency names exist and sensorForniture is not "Solo Sensori" for enable strips based on payer rules, if have one choise automatic selection
      if (enableFornitures != null && sensorForniture !== "0") {
        document
          .querySelectorAll("input[name^=quantity_stripes]")
          .forEach((i) => {
            console.log(enableFornitures[value], i.value);
            if (!enableFornitures[value].includes(parseInt(i.value))) {
              i.checked = false;
              i.setAttribute("disabled", "disabled");
            } else {
              enableFornitures[value].length === 1
                ? (i.checked = true)
                : (i.checked = false);
              i.removeAttribute("disabled");
            }
          });
      }

      //reset disabled on all total sensors
      document.querySelectorAll("input[name^=total_sensor]").forEach((i) => {
        i.removeAttribute("disabled");
      });

      //set disabled based on frequency
      switch (value) {
        case "1": {
          //1 mese
          document
            .querySelectorAll("input[name^=total_sensor]")
            .forEach((i) => {
              console.log(value, i.value);
              if (i.value !== "2") {
                i.checked = false;
                i.setAttribute("disabled", "disabled");
              }
            });
          break;
        }

        case "3": {
          //3 mesi
          document
            .querySelectorAll("input[name^=total_sensor]")
            .forEach((i) => {
              console.log(value, i.value);
              if (i.value === "2") {
                i.checked = false;
                i.setAttribute("disabled", "disabled");
              }
            });
          break;
        }

        case "-3": {
          //3 mesi
          document
            .querySelectorAll("input[name^=total_sensor]")
            .forEach((i) => {
              console.log(value, i.value);
              if (i.value === "2") {
                i.checked = false;
                i.setAttribute("disabled", "disabled");
              }
            });
          break;
        }

        case "6": {
          //6 mesi
          document
            .querySelectorAll("input[name^=total_sensor]")
            .forEach((i) => {
              if (
                i.value === "2" ||
                i.value === "6" ||
                i.value === "7" ||
                i.value === "20"
              ) {
                i.checked = false;
                i.setAttribute("disabled", "disabled");
              }
            });
          break;
        }
      }
    }
  };

  //control close of modal
  const handleClose = () => {
    setShowModal(false);
  };

  //control show of modal and save event target
  const handleShow = () => {
    setShowModal(true);
    setActionMessage(
      "Questo paziente ha già un ordine in corso. Sicuro di voler creare un nuovo ordine?",
    );
  };

  //control action of modal
  const handleAction = () => {
    handleCreate();
    setShowModal(false);
  };

  useEffect(() => {
    if (hasPendingOrder !== null) {
      if (hasPendingOrder === true) {
        handleShow();
        setHasPendingOrder(null);
      } else {
        handleCreate();
      }
    }
  }, [hasPendingOrder]);

  //handle submit of form
  const handleSubmit = (e) => {
    e.preventDefault();
    preventDoubleClick();
    checkPendingOrder(props.patientId, { setHasPendingOrder, setIsLoading });
  };

  //create order
  const handleCreate = () => {
    clearInputNameRandomString();
    setHasPendingOrder(null);
    const redirect = "/dettaglio-ordini/";

    const form = document.getElementById("formAddOrder");
    emptyError();
    const formData = new FormData(form);
    formData.delete("duration_months");
    formData.append("duration_months", durationMonths);

    const emptyfields = document.querySelector("span.error_empty_field");
    if (!emptyfields) {
      preventDoubleClick();
      addOrder({
        formData,
        setErrors,
        setIsLoading,
        setMessage,
        navigate,
        redirect,
      });
    }

    generateRandomStringToAddInputName();
  };

  //edit order
  const handleEdit = (e) => {
    e.preventDefault();
    clearInputNameRandomString();

    let totalSensors;
    const id = document.getElementById("order_id").value;
    const redirect = "/dettaglio-ordini/";

    const form = document.getElementById("formAddOrder");
    emptyError();

    const emptyfields = document.querySelector("span.error_empty_field");
    if (!emptyfields) {
      const formData = new FormData(form);
      for (let i of document.querySelectorAll("input[name^=total_sensors]")) {
        if (i.checked) {
          totalSensors = i.value;
        }
      }

      const selectedMonths = document.querySelector(
        "input#sensor-" + totalSensors,
      ).value;

      formData.delete("duration_months");
      formData.append("duration_months", selectedMonths);

      preventDoubleClick();
      editOrder({
        formData,
        setErrors,
        setIsLoading,
        setMessage,
        navigate,
        redirect,
        id,
      });
    }

    generateRandomStringToAddInputName();
  };

  //handle radio select for get correct value
  const handleRadioSelect = (e) => {
    const selectedMonths = e.target.value;
    setDurationMonths(
      document.querySelector("input#sensor-" + selectedMonths).value,
    );
  };

  return (
    <>
      <ConfirmationModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleAction}
        actionMessage={actionMessage}
        isButtonDisabled={isButtonDisabled}
      />
      <Form id={"formAddOrder"} className={"row py-3 text-start mx-0 px-0"}>
        {message && (
          <Alert
            variant={"success"}
            onClick={() => {
              setMessage(null);
            }}
            dismissible
          >
            {" "}
            {message}{" "}
          </Alert>
        )}
        {errors && typeof errors === "string" && (
          <Alert
            variant={"danger"}
            onClick={() => {
              setErrors(null);
            }}
            dismissible
          >
            {" "}
            {errors}{" "}
          </Alert>
        )}

        <input
          type={"hidden"}
          value={props.patientId ? props.patientId : ""}
          name={"patient_id"}
        />
        <input
          type={"hidden"}
          value={props.payerId ? props.payerId : ""}
          name={"payer_id"}
        />
        <input
          type={"hidden"}
          id="order_id"
          value={
            props.orderDetail && props.orderDetail.id
              ? props.orderDetail.id
              : ""
          }
          name={"id"}
        />
        <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0   mt-3"}>
          <Col
            className={
              "bkg-primary text-white text-start rounded-top-3 d-flex align-items-center"
            }
          >
            <p className={"mb-0 py-2"}>Nuovo Ordine</p>
          </Col>

          <Row className={"py-32"}>
            <Col md={4}>
              <Form.Group className={"my-2 "}>
                <Row className={"align-items-center d-flex"}>
                  <Form.Label className={"col-md-3 mb-0"}>
                    Rif. Ordine
                  </Form.Label>
                  <Col className={"col-md-9 "}>
                    <Form.Control
                      type={"text"}
                      name={"id_ordine"}
                      className={"border-secondary"}
                      defaultValue={
                        props.orderDetail && props.orderDetail.id_ordine
                          ? props.orderDetail.id_ordine
                          : ""
                      }
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className={"my-2"}>
                <Row className={"align-items-center d-flex "}>
                  <Form.Label className={"col-md-3 mb-0"}>
                    Tipologia fornitura
                  </Form.Label>
                  <Col className={"col-md-9 "}>
                    <Form.Select
                      onChange={handleTipologyChange}
                      data-action={"stripe"}
                      required
                      name={"forniture_type"}
                      className={"border-secondary"}
                    >
                      <option value={""}>Seleziona</option>
                      {payerRules &&
                        JSON.parse(payerRules.forniture_type_options).map(
                          (value) => {
                            return (
                              <option
                                key={value}
                                selected={
                                  props.orderDetail &&
                                  props.orderDetail.forniture_type === value &&
                                  "selected"
                                }
                                className={"forniture_type_" + value}
                                value={value}
                              >
                                {" "}
                                {value === 0 ? "Solo Sensori" : "Completa"}
                              </option>
                            );
                          },
                        )}
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className={"my-2"}>
                <Row className={"align-items-center d-flex "}>
                  <Form.Label className={"col-md-3 mb-0"}>
                    Tipologia sensore
                  </Form.Label>

                  <Col className={"col-md-9 px-0"}>
                    <Row className={"card px-2 formCard border-secondary"}>
                      {payerRules &&
                        JSON.parse(payerRules.sensor_type_options).map(
                          (value) => {
                            return (
                              <Form.Check
                                key={value}
                                onChange={handleTipologyChange}
                                data-action={"sensor"}
                                required
                                value={value}
                                defaultChecked={
                                  props.orderDetail &&
                                  props.orderDetail.sensor_type === value
                                }
                                type={"radio"}
                                name={"sensor_type"}
                                label={"FreeStyle Libre " + value}
                              ></Form.Check>
                            );
                          },
                        )}
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className={"my-2"}>
                <Row className={"align-items-center d-flex"}>
                  <Form.Label className={"col-md-3 mb-0"}>
                    Frequenza dispensazioni
                  </Form.Label>
                  <Col className={"col-md-9 px-0"}>
                    <Row
                      className={"card px-2 formCard border-secondary"}
                      onClick={handleTipologyChange}
                    >
                      {payerRules &&
                        JSON.parse(payerRules.dispensing_frequency_options).map(
                          (value) => {
                            return (
                              <Form.Check
                                key={value}
                                data-action={"frequency"}
                                required
                                type={"radio"}
                                defaultChecked={
                                  props.orderDetail &&
                                  props.orderDetail.dispensing_frequency ===
                                    value
                                }
                                value={value}
                                name={"dispensing_frequency"}
                                label={
                                  frequencyNames ? frequencyNames[value] : null
                                }
                                disabled={
                                  props.orderDetail &&
                                  payerRules.sensors_frequency &&
                                  !JSON.parse(payerRules.sensors_frequency)[
                                    props.orderDetail.sensor_type
                                  ].includes(parseInt(value))
                                }
                              ></Form.Check>
                            );
                          },
                        )}
                    </Row>
                  </Col>
                </Row>
              </Form.Group>
              <Form.Group className={"my-2"}>
                <Row className={"align-items-center d-flex"}>
                  <Form.Label className={"col-md-3 mb-0"}>
                    Data invio ordine
                  </Form.Label>
                  <Col className={"col-md-9 "}>
                    <DateInput
                      className={
                        "form-control form-control-sm border-secondary"
                      }
                      selectedDate={
                        props.orderDetail
                          ? new Date(props.orderDetail.order_send_timestamp)
                          : new Date()
                      }
                      name={"order_send_timestamp"}
                      minDate={new Date()}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col md={8} className={"d-flex align-items-stretch inlineCheck"}>
              <Row>
                <Form.Group className={"my-2 d-flex row"}>
                  <label className={"col-md-2 mb-0 d-flex align-items-center"}>
                    Durata
                  </label>
                  <Col
                    className={
                      "formCard mx-2 px-3 align-content-stretch d-inline-flex border-secondary"
                    }
                  >
                    <Row
                      className={
                        "row mx-0 d-inline-flex align-content-center w-100"
                      }
                    >
                      <Col className={"col-md-12 px-2 py-3 duration"}>
                        {durationPlans && durationPlans.includes("99") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"99"}
                              name={"total_sensors"}
                              label={"Illimitato"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 6 &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 99 &&
                                props.orderDetail.total_sensors === 99
                              }
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-99"}
                              type={"hidden"}
                              name="duration_months"
                              value={"99"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("36") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"78"}
                              name={"total_sensors"}
                              label={"36 mesi 78 sensori"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 6 &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 36 &&
                                props.orderDetail.total_sensors === 78
                              }
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-78"}
                              type={"hidden"}
                              name="duration_months"
                              value={"36"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("12") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"26"}
                              name={"total_sensors"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 6 &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 12 &&
                                props.orderDetail.total_sensors === 26
                              }
                              label={"12 mesi 26 sensori"}
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-26"}
                              type={"hidden"}
                              name="duration_months"
                              value={"12"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("9") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              name={"total_sensors"}
                              value={"20"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 9 &&
                                props.orderDetail.total_sensors === 20
                              }
                              label={"9 mesi 20 sensori"}
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-20"}
                              type={"hidden"}
                              name="duration_months"
                              value={"9"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("6") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"13"}
                              name={"total_sensors"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 6 &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 6 &&
                                props.orderDetail.total_sensors === 13
                              }
                              label={"6 mesi 13 sensori"}
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-13"}
                              type={"hidden"}
                              name="duration_months"
                              value={"6"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("3") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              name={"total_sensors"}
                              value={"7"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 3 &&
                                props.orderDetail.total_sensors === 7
                              }
                              label={"3 mesi 7 sensori"}
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-7"}
                              type={"hidden"}
                              name="duration_months"
                              value={"3"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("1") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"6"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 3
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 1 &&
                                props.orderDetail.total_sensors === 6
                              }
                              name={"total_sensors"}
                              label={`3 mesi 6 sensori`}
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-6"}
                              type={"hidden"}
                              name="duration_months"
                              value={"1"}
                            />
                          </>
                        )}
                        {durationPlans && durationPlans.includes("2") && (
                          <>
                            <Form.Check
                              onChange={handleRadioSelect}
                              required
                              type={"radio"}
                              value={"2"}
                              name={"total_sensors"}
                              label={"12 mesi 2 sensori"}
                              disabled={
                                props.orderDetail &&
                                props.orderDetail.dispensing_frequency !== 1
                              }
                              defaultChecked={
                                props.orderDetail &&
                                props.orderDetail.duration_months === 2 &&
                                props.orderDetail.total_sensors === 2
                              }
                              inline
                            ></Form.Check>
                            <input
                              id={"sensor-2"}
                              type={"hidden"}
                              name="duration_months"
                              value={"2"}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group className={"my-2 d-flex row"}>
                  <label className={"col-md-2 mb-0 d-flex align-items-center"}>
                    Lettore
                  </label>
                  <Col
                    className={
                      "formCard mx-2 px-3 align-content-stretch d-inline-flex border-secondary"
                    }
                  >
                    <Row
                      className={
                        "row mx-0 d-inline-flex align-content-center w-100"
                      }
                    >
                      {payerRules && payerRules.include_reader === 1 && (
                        <Col className={"col-md-12 px-2  py-3"}>
                          <Form.Check
                            type={"radio"}
                            className={"include_reader"}
                            name={"include_reader"}
                            value={1}
                            label={"Si"}
                            disabled={
                              props.orderDetail &&
                              props.orderDetail.sensor_type === 3
                                ? "required"
                                : false
                            }
                            required={
                              props.orderDetail &&
                              props.orderDetail.forniture_type !== 0
                                ? "required"
                                : false
                            }
                            defaultChecked={
                              props.orderDetail &&
                              props.orderDetail.include_reader === true
                            }
                            inline
                          ></Form.Check>
                          <Form.Check
                            type={"radio"}
                            className={"include_reader"}
                            name={"include_reader"}
                            label={"No"}
                            value={0}
                            disabled={
                              props.orderDetail &&
                              props.orderDetail.sensor_type === 3
                                ? "required"
                                : false
                            }
                            defaultChecked={
                              props.orderDetail &&
                              props.orderDetail.include_reader === false
                            }
                            required={
                              props.orderDetail &&
                              props.orderDetail.forniture_type !== 0
                                ? "required"
                                : false
                            }
                            inline
                          ></Form.Check>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Form.Group>
                <Form.Group className={"my-2 d-flex row"}>
                  <label className={"col-md-2 mb-0 d-flex align-items-center"}>
                    Strisce / Lancette per ogni invio
                  </label>
                  <Col
                    className={
                      "formCard mx-2 px-3 align-content-stretch d-inline-flex border-secondary"
                    }
                  >
                    <Row
                      className={
                        "row d-inline-flex align-content-center mx-0 w-100"
                      }
                    >
                      <Col className={"col-md-12 px-2 py-3"}>
                        {payerRules &&
                          payerRules.include_reader === 1 &&
                          JSON.parse(payerRules.quantity_stripes).map(
                            (value) => {
                              return (
                                <Form.Check
                                  key={value}
                                  type={"radio"}
                                  name={"quantity_stripes"}
                                  label={value}
                                  value={value}
                                  disabled={
                                    props.orderDetail &&
                                    props.orderDetail.forniture_type === 0
                                  }
                                  required={
                                    props.orderDetail &&
                                    props.orderDetail.forniture_type !== 0
                                      ? "required"
                                      : false
                                  }
                                  defaultChecked={
                                    props.orderDetail &&
                                    props.orderDetail.quantity_stripes === value
                                  }
                                  inline
                                ></Form.Check>
                              );
                            },
                          )}
                      </Col>
                    </Row>
                  </Col>
                </Form.Group>
              </Row>
            </Col>

            <Form.Group className={"my-2 d-flex row"}>
              <Row className={"align-items-center d-flex px-0 mx-0"}>
                <Form.Label className={"col-md-1 mb-0"}>Note</Form.Label>
                <Col className={"col-md-11 "}>
                  <Form.Control
                    className={"border-secondary"}
                    maxLength={200}
                    as={"textarea"}
                    defaultValue={
                      props.orderDetail && props.orderDetail.notes
                        ? props.orderDetail.notes
                        : ""
                    }
                    name={"note"}
                    style={{ resize: "vertical" }}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Row>
        </Row>

        <Row className={"text-center justify-content-end px-0 mx-0"}>
          <Col className={"col-md-3 text-end px-0"}>
            <Button
              variant="primary"
              type="submit"
              onClick={props.orderDetail ? handleEdit : handleSubmit}
              disabled={isButtonDisabled}
              className={"mt-3 rounded-5 px-5  text-uppercase"}
            >
              Salva Ordine
            </Button>
          </Col>
        </Row>
      </Form>

      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default NewOrder;
