import axiosInstance from "../AxiosInstance";
import { getFilenameFromContentDisposition } from "../../Utils/GetHeaderFileName";

//recupera lista ordini con filtri e oridnamento
export const retrieveOrdersList = ({
  setOrdersList,
  setIsLoading,
  setPagination,
  perPage,
  pageUrl,
  search,
  filterSort,
  setTotalRecords,
  setErrors,
}) => {
  setIsLoading(true);
  const symbol = pageUrl.includes("?") ? "&" : "?";
  let url = pageUrl + symbol + perPage;

  const query = search
    ? Object.keys(search)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(search[key]),
        )
        .join("&")
    : null;

  const filter = filterSort
    ? Object.keys(filterSort)
        .map(
          (key) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(filterSort[key]),
        )
        .join("&")
    : null;

  if (query !== null) url += "&" + query;
  if (filter !== null) url += "&" + filter;

  axiosInstance
    .get(url)
    .then((response) => {
      // Handle successful response (status code 200)
      setOrdersList(response.data.data.data);
      setPagination(response.data.data.links);
      setTotalRecords(response.data.data.total);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        // setErrors(error.response.data.message)
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        // setErrors(error)
        console.error("Error:", error);
      }
    });
};

export const retrieveOrderDetail = ({
  setOrderDetail,
  setIsLoading,
  pageUrl,
  id,
  setErrors,
}) => {
  setIsLoading(true);
  let url = pageUrl + id;

  axiosInstance
    .get(url)
    .then((response) => {
      // Handle successful response (status code 200)
      console.log(response.data);
      setOrderDetail(response.data.data);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        // setErrors(error.response.data.message)
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        // setErrors(error)
        console.error("Error:", error);
      }
    });
};

//export list of order in excel
export const exportOrderList = (
  formData,
  { setIsLoading, setErrors, setMessage },
) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/orders/v1/export/redirect", formData)
    .then((response) => {
      window.location.href = response.data.data.url;
      setMessage(response.data.message);
      setIsLoading(false);
    })
    .catch((error) => {
      //  setIsLoading(false)
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.errors);
        setIsLoading(false);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        console.log(error);
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        setIsLoading(false);
        console.error("Error:", error);
      }
    });
};

export const addOrder = ({
  formData,
  setErrors,
  setIsLoading,
  setMessage,
  navigate,
  redirect,
}) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/orders/v1", formData)
    .then((response) => {
      // Handle successful response (status code 200)
      setMessage(response.data.message);
      setTimeout(function () {
        navigate(redirect + response.data.data.id);
      }, 1500);
    })
    .catch((error) => {
      setIsLoading(false);

      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setIsLoading(false);
        setErrors(error.response.data.errors);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        console.error("Error:", error);
      }
    });
};

//update status order
export const updateStatus = async (
  id,
  id_status,
  setIsLoading,
  setMessage,
  setOrderDetail,
  setErrors,
) => {
  await setIsLoading(true);

  try {
    const response = await axiosInstance.put(
      "/api/orders/v1/" + id + "/status",
      {
        status: id_status,
      },
    );

    await setIsLoading(false);
    await setMessage(response.data.message);

    if (setOrderDetail) {
      await setOrderDetail(response.data);
    }
    return response.data;
  } catch (error) {
    setIsLoading(false);
    if (error.response && error.response.status === 422) {
      // Handle the 422 response (validation error)
      setErrors(error?.response?.data?.message);
    } else {
      // Handle other errors (e.g., network issues)
      setErrors(error?.response?.data?.message);
      console.error("Error:", error);
    }
  }
};

//update rif order
export const updateRifOrder = (
  id,
  id_ordine,
  { setIsLoading, setMessage, setErrors },
) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/orders/v1/" + id + "/edit_order_reference", {
      id_ordine: id_ordine,
    })
    .then((response) => {
      // Handle successful response (status code 200)
      setMessage(response.data.message);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    })
    .catch((error) => {
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        console.error("Error:", error);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
};

//update notes
export const updateNotes = (
  id,
  note,
  { setIsLoading, setMessage, setErrors },
) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/orders/v1/" + id + "/notes", {
      note: note,
    })
    .then((response) => {
      // Handle successful response (status code 200)
      setMessage(response.data.message);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    })
    .catch((error) => {
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setErrors(error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setErrors(error.response.data.message);
        console.error("Error:", error);
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
};

export const editOrder = ({
  formData,
  setErrors,
  setIsLoading,
  setMessage,
  navigate,
  redirect,
  id,
}) => {
  setIsLoading(true);

  axiosInstance
    .post("/api/orders/v1/" + id, formData)
    .then((response) => {
      // Handle successful response (status code 200)
      setMessage(response.data.message);

      setTimeout(function () {
        navigate(redirect + response.data.data.id);
      }, 1500);
    })
    .catch((error) => {
      if (error.response && error.response.status === 422) {
        // Handle the 422 response (validation error)
        setIsLoading(false);
        setErrors(error.response.data.errors);
        console.log("Validation Errors:", error.response.data.message);
      } else {
        // Handle other errors (e.g., network issues)
        setIsLoading(false);
        setErrors(error.response.data.message);
        console.error("Error:", error);
      }
    });
};

//export order detail
export const exportOrderDetail = (orderId, { setIsLoading, setErrors }) => {
  setIsLoading(true);

  fetch(
    process.env.REACT_APP_BASE_URL + "/api/orders/v1/" + orderId + "/export",
    {
      method: "GET",
      responseType: "blob", // Important,
      headers: {
        "X-JWT-Token": localStorage.getItem("jwt"),
        Authorization: "Bearer " + localStorage.getItem("sanctum"),
      },
    },
  )
    .then((res) => {
      const filename = getFilenameFromContentDisposition(res.headers);
      return res.blob().then((blob) => ({ filename, blob }));
    })
    .then(
      ({ filename, blob }) => {
        const outputFilename =
          filename ?? `export_dettaglio_ordine_` + orderId + `.pdf`;

        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(new Blob([blob]), {
          type: "text/plain",
        });
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        setTimeout(() => {
          link.click();
          document.body.removeChild(link); // Remove the link after download
        }, 100);
        setIsLoading(false);
      },
      (err) => {
        console.log(err);
        setErrors("Errore esportazione. Contatta l'amministratore!");
      },
    );
};
