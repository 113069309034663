import React, {useEffect, useState} from "react";
import "../../assets/css/Sidebar.css";
import {BsCart2, BsChevronLeft, BsChevronRight, BsListUl, BsPeople, BsTools,} from "react-icons/bs";
import {VscGraph} from "react-icons/vsc";
import {CiSettings} from "react-icons/ci";
import logoAbbott from "../../assets/img/abbott.png";
import {Link} from "react-router-dom";
import {Role} from "../../Utils/Role";
import {IoTicketOutline} from "react-icons/io5";
import AddTicketButton from "../Ticket/AddTicketButton";
import {FaArrowAltCircleDown, FaArrowAltCircleUp} from "react-icons/fa";
import {LiaFileInvoiceDollarSolid} from "react-icons/lia";

const Sidebar = () => {
  const [show, setShow] = useState(
    localStorage.hasOwnProperty("showSide")
      ? localStorage.getItem("showSide")
      : "open",
  );
  const [tokenTicket, setTokenTicket] = useState();
  const [role, setRole] = useState(null);
  const [canDownloadPod, setCanDownloadPod] = useState(false);

  //menu show or close open
  const [showSubmenu, setShowSubmenu] = useState({
    patient: true,
    order: true,
    instruments: true,
    report: false,
    billing: false,
    tickets: false,
    config: false,
  });

  useEffect(() => {
    setRole(Role());
    setShow(
      localStorage.hasOwnProperty("showSide")
        ? localStorage.getItem("showSide")
        : "open",
    );
    setCanDownloadPod(
      localStorage.hasOwnProperty("userData")
        ? JSON.parse(localStorage.getItem("userData")).can_download_pods
        : false,
    );

    const timeoutTokenTickets = setTimeout(() => {
      setTokenTicket(localStorage.getItem("jwtTicket"));
    }, 500);

    return () => {
      clearTimeout(timeoutTokenTickets);
    };
  }, []);

  console.log('role:', role);

  useEffect(() => {
    if (!tokenTicket) {
      setTokenTicket(localStorage.getItem("jwtTicket"));
    }
  }, [tokenTicket]);

  //set menu open or close
  const handleShowSubmenu = (submenu) => {
    let tempSubmenu = showSubmenu;
    tempSubmenu[submenu] = !tempSubmenu[submenu];
    setShowSubmenu(tempSubmenu);
  };

  //return to top when click on nav
  const scrollToTop = () => {
    document
      .querySelector(".mainContent")
      .scrollIntoView({ behavior: "smooth" });
  };

  const toggleSidebar = () => {
    if (show === "open") {
      setShow("closed");
      localStorage.setItem("showSide", "closed");
    } else {
      setShow("open");
      localStorage.setItem("showSide", "open");
    }
  };

  const mouseEnter = () => {
    document.querySelector(".sidebar").classList.add("hoverShow");
  };
  const mouseLeave = () => {
    document.querySelector(".sidebar").classList.remove("hoverShow");
  };

  return (
    <>
      <div
        className={`py-4 px-2 shadow sidebar ${show === "open" ? "" : "hiddenSide"}`}
      >
        <div
          className={"test"}
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
        >
          <nav className={"nav text-start col-md-12 p-2 text-white"}>
            <ul className={"navigation-menu "}>
              {/* patients */}
              <a
                href={"#"}
                onClick={() => handleShowSubmenu("patient")}
                className={"text-decoration-none text-white"}
              >
                <p className={""}>
                  <BsPeople className={"me-2 border-light text-primary fs-5"} />
                  Pazienti
                  {showSubmenu && showSubmenu?.patient ? (
                    <FaArrowAltCircleDown className={"mx-2"} />
                  ) : (
                    <FaArrowAltCircleUp className={"mx-2"} />
                  )}
                </p>
              </a>
              {showSubmenu && showSubmenu.patient && (
                <>
                  {!!role &&
                    !["referente_aziendale", "support"].includes(role) && (
                      <li className={"d-flex align-items-center my-2 subLi"}>
                        <Link
                          onClick={scrollToTop}
                          to={"inserimento-pazienti"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white "}>Nuovo Paziente</span>
                        </Link>
                      </li>
                    )}
                  <li className={"d-flex align-items-center  my-2"}>
                    <Link
                      onClick={scrollToTop}
                      to={"lista-pazienti"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Ricerca Pazienti
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {/* order */}
              <a
                href={"#"}
                onClick={() => handleShowSubmenu("order")}
                className={"text-decoration-none text-white"}
              >
                <p className={""}>
                  <BsCart2 className={"me-2 border-light text-primary fs-5"} />
                  Ordini
                  {showSubmenu && showSubmenu?.order ? (
                    <FaArrowAltCircleDown className={"mx-2"} />
                  ) : (
                    <FaArrowAltCircleUp className={"mx-2"} />
                  )}
                </p>
              </a>
              {showSubmenu && showSubmenu?.order && (
                <>
                  {!!role &&
                    !["referente_aziendale", "support"].includes(role) && (
                      <li className={"d-flex align-items-center subLi  my-2 "}>
                        <Link
                          onClick={scrollToTop}
                          to={"inserimento-ordine"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Nuovo Ordine
                          </span>
                        </Link>
                      </li>
                    )}

                  <li className={"d-flex align-items-center subLi  my-2"}>
                    <Link
                      onClick={scrollToTop}
                      to={"/lista-ordini"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Ricerca Ordini
                      </span>
                    </Link>
                  </li>
                  <li className={"d-flex align-items-center  my-2"}>
                    <Link
                      onClick={scrollToTop}
                      to={"/lista-dispensazioni"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Ricerca Dispensazioni
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {/* payers */}
              {!!role &&
                ![
                  "data_entry",
                  "referente_aziendale",
                  "support",
                  "single_user",
                ].includes(role) && (
                  <li
                    className={
                      "fw-bolder ps-0 d-flex align-items-center subLi my-2"
                    }
                  >
                    <Link
                      onClick={scrollToTop}
                      to={"/registro-payers"}
                      className={"text-decoration-none"}
                    >
                      <BsListUl className={"me-2 text-primary fs-5"} />
                      <span className={"text-white text-decoration-none"}>
                        Registro Payers
                      </span>
                    </Link>
                  </li>
                )}

              {/* instruments */}
              {!!role &&
                (![
                  "data_entry",
                  "support",
                  "referente_aziendale",
                  "single_user",
                ].includes(role) ||
                  canDownloadPod === true) && (
                  <>
                    <a
                      href={"#"}
                      onClick={() => handleShowSubmenu("instruments")}
                      className={"text-decoration-none text-white"}
                    >
                      <p className={""}>
                        <BsTools
                          className={"me-2 border-light text-primary fs-5"}
                        />
                        Strumenti
                        {showSubmenu && showSubmenu?.instruments ? (
                          <FaArrowAltCircleDown className={"mx-2"} />
                        ) : (
                          <FaArrowAltCircleUp className={"mx-2"} />
                        )}
                      </p>
                    </a>
                    {showSubmenu && showSubmenu?.instruments && (
                      <>
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "referente_aziendale",
                            "single_user",
                          ].includes(role) && (
                            <li
                              className={
                                "d-flex align-items-center subLi my-2 "
                              }
                            >
                              <Link
                                onClick={scrollToTop}
                                to={"/lista-pianificazione-dispensazioni"}
                                className={"text-decoration-none"}
                              >
                                <span
                                  className={"text-white text-decoration-none"}
                                >
                                  Ripianificazione
                                </span>
                              </Link>
                            </li>
                          )}
                        {canDownloadPod && (
                          <li
                            className={"d-flex align-items-center subLi my-2"}
                          >
                            <Link
                              onClick={scrollToTop}
                              to={"/download-pod"}
                              className={"text-decoration-none"}
                            >
                              <span
                                className={"text-white text-decoration-none"}
                              >
                                Download POD
                              </span>
                            </Link>
                          </li>
                        )}
                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "referente_aziendale",
                            "single_user",
                          ].includes(role) && (
                            <li
                              className={"d-flex align-items-center subLi my-2"}
                            >
                              <Link
                                onClick={scrollToTop}
                                to={"/gestione-prodotti"}
                                className={"text-decoration-none"}
                              >
                                <span
                                  className={"text-white text-decoration-none"}
                                >
                                  Gestione prodotti
                                </span>
                              </Link>
                            </li>
                          )}

                        {!!role &&
                          ![
                            "data_entry",
                            "support",
                            "referente_aziendale",
                            "single_user",
                          ].includes(role) && (
                            <li className={"d-flex align-items-center my-2"}>
                              <Link
                                onClick={scrollToTop}
                                to={"/export-lombardia"}
                                className={"text-decoration-none"}
                              >
                                <span
                                  className={"text-white text-decoration-none"}
                                >
                                  Export Lombardia
                                </span>
                              </Link>
                            </li>
                          )}
                      </>
                    )}
                  </>
                )}

              {/* reports */}
              <a
                href={"#"}
                onClick={() => handleShowSubmenu("report")}
                className={"text-decoration-none text-white"}
              >
                <p className={""}>
                  <VscGraph className={"me-2 border-light text-primary fs-5"} />
                  Report
                  {showSubmenu && showSubmenu?.report ? (
                    <FaArrowAltCircleDown className={"mx-2"} />
                  ) : (
                    <FaArrowAltCircleUp className={"mx-2"} />
                  )}
                </p>
              </a>
              {showSubmenu && showSubmenu?.report && (
                <>
                  <li className={"d-flex align-items-center subLi  my-2 "}>
                    <Link
                      onClick={scrollToTop}
                      to={"/dispensazioni-inviate"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Dispensazioni Inviate
                      </span>
                    </Link>
                  </li>
                  {!!role &&
                    ![
                      "data_entry",
                      "referente_aziendale",
                      "single_user",
                    ].includes(role) && (
                      <li className={"d-flex align-items-center subLi  my-2 "}>
                        <Link
                          onClick={scrollToTop}
                          to={"/dispensazioni-bloccate"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Dispensazioni Bloccate
                          </span>
                        </Link>
                      </li>
                    )}
                  <li className={"d-flex align-items-center subLi my-2 "}>
                    <Link
                      onClick={scrollToTop}
                      to={"/dispensazioni-programmate"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Dispensazioni Programmate
                      </span>
                    </Link>
                  </li>
                  {!!role &&
                    ![
                      "data_entry",
                      "referente_aziendale",
                      "single_user",
                    ].includes(role) && (
                      <li className={"d-flex align-items-center subLi my-2 "}>
                        <Link
                          onClick={scrollToTop}
                          to={"/dispensazioni-rese"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Dispensazioni Rese
                          </span>
                        </Link>
                      </li>
                    )}
                  {!!role &&
                    ![
                      "data_entry",
                      "referente_aziendale",
                      "single_user",
                    ].includes(role) && (
                      <li className={"d-flex align-items-center subLi my-2 "}>
                        <Link
                          onClick={scrollToTop}
                          to={"/fatturazione"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Fatturazione
                          </span>
                        </Link>
                      </li>
                    )}
                  {!!role &&
                    ![
                      "data_entry",
                      "referente_aziendale",
                      "single_user",
                      "support",
                    ].includes(role) && (
                      <li className={"d-flex align-items-center subLi my-2 "}>
                        <Link
                          to={process.env.REACT_APP_DASHBOARD_LINK}
                          target={"_blank"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Dashboard
                          </span>
                        </Link>
                      </li>
                    )}
                  <li className={"d-flex align-items-center subLi my-2 "}>
                    <Link
                      onClick={scrollToTop}
                      to={"/pazienti-in-scadenza"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Pazienti in scadenza
                      </span>
                    </Link>
                  </li>
                  <li className={"d-flex align-items-center  my-2 "}>
                    <Link
                      onClick={scrollToTop}
                      to={"/pazienti-non-rinnovati"}
                      className={"text-decoration-none"}
                    >
                      <span className={"text-white text-decoration-none"}>
                        Pazienti non rinnovati
                      </span>
                    </Link>
                  </li>
                </>
              )}

              {!!role &&
                ![
                  "data_entry",
                  "referente_aziendale",
                  "single_user",
                  "support",
                ].includes(role) && (
                  <>
                    <a
                      href={"#"}
                      onClick={() => handleShowSubmenu("billing")}
                      className={"text-decoration-none text-white"}
                    >
                      <p className={""}>
                        <LiaFileInvoiceDollarSolid
                          className={"me-2 border-light text-primary fs-5"}
                        />
                        Fatturazione
                        {showSubmenu && showSubmenu?.billing ? (
                          <FaArrowAltCircleDown className={"mx-2"} />
                        ) : (
                          <FaArrowAltCircleUp className={"mx-2"} />
                        )}
                      </p>
                    </a>
                    {showSubmenu && showSubmenu?.billing && (
                      <>
                        <li className={"d-flex align-items-center  subLi my-2"}>
                          <Link
                            onClick={scrollToTop}
                            to={"lista-fatture"}
                            className={"text-decoration-none"}
                          >
                            <span className={"text-white text-decoration-none"}>
                              Lista Fatture
                            </span>
                          </Link>
                        </li>
                        <li className={"d-flex align-items-center  subLi my-2"}>
                          <Link
                            onClick={scrollToTop}
                            to={"aggiungi-fattura-canone"}
                            className={"text-decoration-none"}
                          >
                            <span className={"text-white text-decoration-none"}>
                              Nuova Fattura - Canone
                            </span>
                          </Link>
                        </li>
                        <li className={"d-flex align-items-center  my-2"}>
                          <Link
                            onClick={scrollToTop}
                            to={"aggiungi-fattura-quantita"}
                            className={"text-decoration-none"}
                          >
                            <span className={"text-white text-decoration-none"}>
                              Nuova Fattura - Quantità
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                  </>
                )}

              {/* tickets */}
              {Number(process.env.REACT_APP_TICKET_ENABLED) === 1 &&
                !!role &&
                !["data_entry", "single_user", "referente_aziendale"].includes(
                  role,
                ) && (
                  <>
                    <a
                      href={"#"}
                      onClick={() => handleShowSubmenu("tickets")}
                      className={"text-decoration-none text-white"}
                    >
                      <p className={""}>
                        <IoTicketOutline
                          className={"me-2 border-light text-primary fs-5"}
                        />
                        Tickets
                        {showSubmenu && showSubmenu?.tickets ? (
                          <FaArrowAltCircleDown className={"mx-2"} />
                        ) : (
                          <FaArrowAltCircleUp className={"mx-2"} />
                        )}
                      </p>
                    </a>
                    {showSubmenu && showSubmenu?.tickets && (
                      <>
                        <AddTicketButton />
                        <li className={"d-flex align-items-center  my-2"}>
                          <Link
                            onClick={scrollToTop}
                            to={
                              process.env.REACT_APP_TICKET_URL +
                              "/auth?service=libredesk&jwt=" +
                              tokenTicket
                            }
                            target={"_blank"}
                            className={"text-decoration-none"}
                          >
                            <span className={"text-white text-decoration-none"}>
                              Lista Ticket
                            </span>
                          </Link>
                        </li>
                      </>
                    )}
                  </>
                )}

              {/* configurations */}
              {!!role &&
                ![
                  "data_entry",
                  "support",
                  "referente_aziendale",
                  "single_user",
                  "superuser",
                ].includes(role) && (
                  <>
                    <a
                      href={"#"}
                      onClick={() => handleShowSubmenu("config")}
                      className={"text-decoration-none text-white"}
                    >
                      <p className={""}>
                        <CiSettings
                          className={"me-2 border-light text-primary fs-5"}
                        />
                        Configurazione
                        {showSubmenu && showSubmenu?.config ? (
                          <FaArrowAltCircleDown className={"mx-2"} />
                        ) : (
                          <FaArrowAltCircleUp className={"mx-2"} />
                        )}
                      </p>
                    </a>
                    {showSubmenu && showSubmenu?.config && (
                      <li className={"d-flex align-items-center  my-2"}>
                        <Link
                          onClick={scrollToTop}
                          to={"riprocessa-dispensazione"}
                          className={"text-decoration-none"}
                        >
                          <span className={"text-white text-decoration-none"}>
                            Riprocessamento
                          </span>
                        </Link>
                      </li>
                    )}
                  </>
                )}
            </ul>

            <div className={"w-100 text-end"}>
              <div
                className={`openSidebar bg-dark d-inline-flex  justify-content-center align-items-stretch text-white ${show === "open" ? "show" : "hide"}`}
                onClick={toggleSidebar}
              >
                <a
                  className={"text-white d-block w-100 text-center"}
                  style={{ paddingTop: "5px" }}
                  href={"#"}
                >
                  {show === "open" ? <BsChevronLeft /> : <BsChevronRight />}
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div className={"col position-relative bottom-0 pb-3"}>
          <img alt={""} className={"img-fluid"} src={logoAbbott} />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
