import { Button, Col, Form, Row } from "react-bootstrap";
import IconTooltip from "../Layout/IconsTooltip";
import { BiEraser } from "react-icons/bi";
import React, { useEffect, useRef, useState } from "react";
import { retrievePayerAndDistricts } from "../../Api/Districts/DistrictsFetch";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import { emptyError } from "../Errors";
import Select from "react-select";
import { Grouped } from "../../Utils/Grouped";
import { getStatusesSearch } from "../../Api/Billing/BillingFetch";

const SearchBillingFeeQuantity = (props) => {
  const selectRefs = useRef([]);
  const [districtsData, setDistrictsData] = useState([]);
  const [payersData, setPayersData] = useState([]);
  const [payerId, setPayerId] = useState("");
  const [errors, setErrors] = useState();
  const [monthOptions, selectMonthOptions] = useState([]);
  const [groupedPayers, setGroupedPayers] = useState();
  const [statusPayers, setStatusPayers] = useState([]);

  // function for add refenrece to array
  const addSelectRef = (ref) => {
    if (ref && !selectRefs.current.includes(ref)) {
      selectRefs.current.push(ref);
    }
  };

  // function for reset all multi select
  const resetAllSelects = () => {
    selectRefs.current.forEach((ref) => {
      ref.clearValue();
    });
  };

  //generate last 12 month option select
  useEffect(() => {
    const today = new Date();
    const months = [];

    for (let i = 0; i < 3; i++) {
      const prevMonth = new Date(today);
      today.setDate(1);
      prevMonth.setMonth(today.getMonth() - i);
      const monthName = prevMonth.toLocaleString("default", { month: "long" });
      const year = prevMonth.getFullYear();
      months.unshift({
        monthNumber: prevMonth.getMonth() + 1,
        month: monthName,
        year: year.toString(),
      });
    }

    selectMonthOptions(months.reverse());
    retrievePayerAndDistricts(
      payerId,
      { setPayersData, setErrors },
      null,
      "/all",
    );
  }, []);

  //get status filter by payer id
  useEffect(() => {
    getStatusesSearch(payerId, props.title === "Quantità" ? 1 : 2, {
      setErrors,
      setStatusPayers,
    });
  }, [payerId, props.title]);

  //filter district by payer select
  useEffect(() => {
    if (payersData.length === 1) {
      setPayerId(payersData[0].payer_id);
    }

    setDistrictsData(
      payersData.filter((payer) => String(payer.payer_id) === payerId),
    );
  }, [payerId, payersData]);

  //grouped payer
  useEffect(() => {
    setGroupedPayers(Grouped(payersData, "region"));
  }, [payersData]);

  //clear input field
  const clearInput = () => {
    setErrors(undefined);
    props.setSearch({});
    for (let i of document.querySelectorAll("form input, form select")) {
      i.value = "";
    }

    resetAllSelects();
  };

  //handle search
  const handleSearch = (e) => {
    clearInputNameRandomString();
    props.setSearch({});
    props.setPageUrl(
      "/api/billing/v1/" +
        (props.title === "Canone" ? "fee" : "quantity") +
        "?page=1",
    );
    e.preventDefault();

    emptyError();
    const emptyfields = document.querySelector("span.error_empty_field");

    if (!emptyfields) {
      const monthOption = document.getElementById("month");
      document.getElementById("year").value =
        monthOption.options[monthOption.selectedIndex].getAttribute(
          "data-year",
        );

      const search = {};

      for (let i of document.querySelectorAll("input")) {
        if (i.value !== "") {
          const name = i.getAttribute("name");
          let value = i.value;

          if (process.env.REACT_APP_ENVIRONMENT === "staging") {
            value = i.value > 1000000 ? i.value - 1000000 : i.value;
          }

          if (!search[name]) {
            search[name] = [value];
          } else {
            search[name].push(value);
          }
        }
      }

      for (let i of document.querySelectorAll("form select")) {
        if (i.value !== "") {
          const name = i.getAttribute("name");
          let value = i.value === "0" ? "%22" + i.value + "%22" : i.value;

          if (i.multiple) {
            value = [];
            for (let option = 0; option < i.options.length; option++) {
              if (i.options[option].selected) {
                value.push(i.options[option].value);
              }
            }
          }

          if (!search[name]) {
            search[name] = [value];
          } else {
            search[name].push(value);
          }
        }
      }

      props.setSearch((prevSearch) => ({
        ...prevSearch,
        ...search,
      }));
    }

    generateRandomStringToAddInputName();
  };

  return (
    <>
      <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0"}>
        <Col
          className={
            "bkg-primary text-white text-start rounded-top-3 align-items-center"
          }
        >
          <Row>
            <Col md={8}>
              <p className={"mb-0 py-2"}>Nuova Fattura {props.title}</p>
            </Col>
            <Col
              md={4}
              className={
                "d-flex justify-content-end align-items-center position-relative"
              }
            >
              <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                <BiEraser className={"fs-3 text-second d-block"}></BiEraser>
              </IconTooltip>
            </Col>
          </Row>
        </Col>

        <Form className={"row py-3 text-start"} id={"searchForm"}>
          <Form.Group className={"my-2"}>
            <Row>
              <Form.Label className={"col-md-1"} column={false}>
                Payer*
              </Form.Label>
              <Col className={"col-md-3 "}>
                <Form.Select
                  name={"filters[payer_id]"}
                  className={"d-inline form-select-md"}
                  disabled={props.patientDetail && "disabled"}
                  data-value={
                    props.patientDetail && props.patientDetail.district_id
                  }
                  required={true}
                  onChange={(e) => setPayerId(e.target.value)}
                >
                  <option value={""}>Seleziona</option>
                  {groupedPayers &&
                    Object.keys(groupedPayers).map((region) => {
                      return (
                        <optgroup key={region} label={region}>
                          {groupedPayers[region]?.map(
                            (
                              payer, // Using optional chaining to handle undefined or null
                            ) => (
                              <option
                                key={payer.payer_id}
                                value={payer.payer_id}
                              >
                                {payer.payer}
                              </option>
                            ),
                          )}
                        </optgroup>
                      );
                    })}
                </Form.Select>
              </Col>

              <Form.Label className={"col-md-1"}>Distretto*</Form.Label>
              <Col className={"col-md-7 "}>
                <Select
                  ref={(ref) => addSelectRef(ref)}
                  id={"district"}
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.data.enable === 0 ? "red" : "black",
                      fontStyle: state.data.enable === 0 ? "italic" : "normal",
                    }),
                  }}
                  options={
                    districtsData
                      ? districtsData.reduce((acc, payer) => {
                          if (payer.districts) {
                            return [
                              ...acc,
                              ...payer.districts.map((district) => ({
                                value: district.id,
                                label: district.title,
                                enable: district.is_enabled,
                              })),
                            ];
                          }
                          return acc;
                        }, [])
                      : []
                  }
                  isMulti={true}
                  placeholder={"Tutti"}
                  name={"filters[district_id]"}
                  noOptionsMessage={() =>
                    "Nessun distretto, selezionare prima il payer"
                  }
                />
              </Col>
            </Row>
          </Form.Group>

          <Form.Group className={"col-md-12 my-2"}>
            <Row>
              <Form.Label className={"col-md-1"}>Sensore*</Form.Label>
              <Col className={"col-md-2"}>
                <Select
                  ref={(ref) => addSelectRef(ref)}
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                  options={[
                    { value: "2", label: "FSL2" },
                    { value: "3", label: "FSL3" },
                  ]}
                  isMulti={props.title === "Quantità"}
                  placeholder={"Seleziona"}
                  name={"filters[sensor_type]"}
                  required={true}
                />
              </Col>

              <Form.Label className={"col-md-1"}>Stato Ordine*</Form.Label>
              <Col className={"col-md-2"}>
                <Form.Select
                  name={"filters[status]"}
                  className={"d-inline form-select-md"}
                  required={true}
                >
                  <option value={""}>Seleziona</option>
                  {statusPayers &&
                    Object.entries(statusPayers).length > 0 &&
                    Object.entries(statusPayers).map(([key, status]) => (
                      <option
                        value={key}
                        selected={Object.entries(statusPayers).length === 1}
                      >
                        {status}
                      </option>
                    ))}
                </Form.Select>
              </Col>

              <Form.Label className={"col-md-1"}>Mesi*</Form.Label>
              <Col className={"col-md-2"}>
                <Form.Select
                  id="month"
                  name={"filters[month]"}
                  className={"d-inline form-select-md"}
                  required={true}
                >
                  <option value={""}>Seleziona</option>
                  {monthOptions &&
                    monthOptions.length > 0 &&
                    monthOptions.map((option, index) => {
                      return (
                        <option
                          key={index}
                          value={option["monthNumber"]}
                          data-year={option["year"]}
                        >
                          {option["month"].toUpperCase() + " " + option["year"]}
                        </option>
                      );
                    })}
                </Form.Select>
                <Form.Control
                  type={"hidden"}
                  id={"year"}
                  name={"filters[year]"}
                ></Form.Control>
              </Col>

              <Form.Label className={"col-md-1"}>POD</Form.Label>
              <Col className={"col-md-2"}>
                <Select
                  ref={(ref) => addSelectRef(ref)}
                  styles={{
                    placeholder: (provided) => ({
                      ...provided,
                      color: "black",
                    }),
                  }}
                  options={[
                    { value: "S", label: "S" },
                    { value: "N", label: "N" },
                    { value: "D", label: "D" },
                    { value: "P", label: "P" },
                  ]}
                  isMulti={true}
                  placeholder={"Seleziona"}
                  name={"filters[documents]"}
                />
              </Col>
            </Row>
          </Form.Group>

          <Row className={"text-center justify-content-center"}>
            <Col className={"col-md-3"}>
              <Button
                variant="primary"
                onClick={handleSearch}
                type="submit"
                className={"mt-3 rounded-5 px-5  text-uppercase"}
              >
                Cerca
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};

export default SearchBillingFeeQuantity;
