import React, { useEffect, useState } from "react";
import { Alert, Row } from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import { TailSpin } from "react-loader-spinner";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import SearchDispensationsForm from "../../Components/Dispensations/SearchForm";
import {
  exportDispensationList,
  retrieveDispensationsList,
  retriveReasonSuspended,
  toggleDispensationStatus,
  updateShippingAt,
} from "../../Api/Dispensations/DispensationsFetch";

const DispensationsList = () => {
  const [dispensationsList, setDispensationsList] = useState({});
  const [pagination, setPagination] = useState({});
  const [pageUrl, setPageUrl] = useState("/api/dispensations/v1");
  const [perPage, setPerPage] = useState("per_page=10");
  const [search, setSearch] = useState({});
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [loading, setIsLoading] = useState(true);
  const [filterSort, setFilterSort] = useState("");
  const [records, setTotalRecords] = useState();
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [showReason, setShowReason] = useState(false);
  const [reasons, setReasons] = useState({});

  useEffect(() => {
    setStatusUpdated(false);
    retrieveDispensationsList({
      setDispensationsList,
      pageUrl,
      filterSort,
      search,
      perPage,
      setIsLoading,
      setTotalRecords,
      setPagination,
    });
    retriveReasonSuspended({ setReasons });
  }, [perPage, filterSort, pageUrl, search, statusUpdated]);

  useEffect(() => {
    generateRandomStringToAddInputName();
  }, []);

  //export table
  const handleExportTable = () => {
    clearInputNameRandomString();
    const form = document.getElementById("searchForm");
    const formData = new FormData(form);

    exportDispensationList(formData, { setIsLoading, setErrors, setMessage });

    generateRandomStringToAddInputName();
  };

  // Function change data
  const handleEditData = (e) => {
    const id = e.target.getAttribute("data-id");
    const dataInput = document.querySelector(
      'div[data-id="' + id + '"] input[name^="datepicker"]',
    );

    if (id !== null) {
      if (dataInput.hasAttribute("disabled")) {
        dataInput.removeAttribute("disabled");
      } else {
        dataInput.setAttribute("disabled", "disabled");
      }
    }
  };

  //function to handle action (no modal)
  const handleSelectReason = (e) => {
    const dispensation_id = e.currentTarget.getAttribute("data-id");

    if (showReason === dispensation_id) {
      setShowReason(false);
    } else {
      setShowReason(dispensation_id);
    }
  };

  //function to handle dispensaction action
  const handleDispensationAction = (e) => {
    setShowReason(false);
    setStatusUpdated(false);

    const dispensation_id = e.currentTarget.getAttribute("data-id");
    const reason_id =
      document.getElementById("reason_id") != null
        ? document.getElementById("reason_id").value
        : null;

    if (dispensation_id != null) {
      setTimeout(() => {
        if (
          e.target.tagName.toLowerCase() === "input" ||
          e.target.tagName.toLowerCase() === "button"
        ) {
          //if changed data updated input
          const shipping_at = document.querySelector(
            'input[data-id="' + dispensation_id + '"][name^="shipping_at"]',
          ).value;
          updateShippingAt([dispensation_id], shipping_at, {
            setErrors,
            setMessage,
            setIsLoading,
          });
          document
            .querySelector('input[data-id="' + dispensation_id + '"]')
            .setAttribute("readonly", "readonly");
        } else {
          toggleDispensationStatus(dispensation_id, reason_id, {
            setErrors,
            setMessage,
            setIsLoading,
          });

          setTimeout(() => {
            setStatusUpdated(true);
          }, 500);
        }
      }, 500);
    }
  };

  return (
    <>
      <Row className={"container-fluid w-100 mx-0 pt-5"}>
        <SearchDispensationsForm
          setSearch={setSearch}
          search={search}
          setPageUrl={setPageUrl}
        ></SearchDispensationsForm>

        {message && (
          <Alert
            className={"mt-4"}
            variant={"success"}
            onClick={() => {
              setMessage(null);
            }}
            dismissible
          >
            {message}
          </Alert>
        )}
        {errors && (
          <Alert
            className={"mt-4"}
            variant={"danger"}
            onClick={() => {
              setErrors(null);
            }}
            dismissible
          >
            {errors}
          </Alert>
        )}

        <CustomTable
          dispensationsList={dispensationsList}
          pagination={pagination}
          setPerPage={setPerPage}
          perPage={perPage}
          setPageUrl={setPageUrl}
          setFilterSort={setFilterSort}
          filterSort={filterSort}
          records={records}
          handleExportTable={handleExportTable}
          handleEditData={handleEditData}
          handleDispensationAction={handleDispensationAction}
          showReason={showReason}
          reasons={reasons}
          handleSelectReason={handleSelectReason}
        ></CustomTable>
      </Row>
      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default DispensationsList;
