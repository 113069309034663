import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "../../assets/css/Login/Login.css";
import { useNavigate } from "react-router-dom";
import { emptyError, handleErrors } from "../../Components/Errors";
import logoFreestyle from "../../assets/img/Logohorizontal.png";
import logoAbbott from "../../assets/img/abbott.png";
import { updatePassword } from "../../Api/Auth/ResetFetch";
import { TailSpin } from "react-loader-spinner";
import AuthContext from "../../Contexts/authContext";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState();
  const [loading, setIsLoading] = useState(false);
  const { passwordExpiry, logout } = useContext(AuthContext);

  useEffect(() => {
    if (errors) {
      handleErrors(errors);
    }
  }, [errors]);

  //handle submit for forgot and reset password
  const submitHandler = (e) => {
    e.preventDefault();
    emptyError();
    const emptyfields = document.querySelector("span.error_empty_field");

    if (!emptyfields) {
      const currentPassword = document.getElementById("current_password").value;
      const newPassword = document.getElementById("new_password").value;
      const passwordConfirmation = document.getElementById(
        "password_confirmation",
      ).value;

      updatePassword({
        currentPassword,
        newPassword,
        passwordConfirmation,
        setErrors,
        navigate,
        setIsLoading,
        logout,
      });
    }
  };

  //check the password validation
  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;

    if (newPassword.length >= 8) {
      document.getElementById("hasMinEightChars").classList.add("text-success");
    } else {
      document
        .getElementById("hasMinEightChars")
        .classList.remove("text-success");
    }
    if (/[A-Z]/.test(newPassword)) {
      document.getElementById("hasUppercase").classList.add("text-success");
    } else {
      document.getElementById("hasUppercase").classList.remove("text-success");
    }
    if (/[a-z]/.test(newPassword) && /\d/.test(newPassword)) {
      document
        .getElementById("hasLowercaseAndNumber")
        .classList.add("text-success");
    } else {
      document
        .getElementById("hasLowercaseAndNumber")
        .classList.remove("text-success");
    }
  };

  return (
    <Row className={"w-100 m-0 bgLogin container-full mw-100"}>
      <Row
        className={
          " justify-content-center text-center me-auto ms-auto align-content-center "
        }
      >
        <Col></Col>
        <Col className={"loginContainer text-white py-5 px-3 rounded-5 shadow"}>
          <Row>
            <Col md={6}>
              {" "}
              <img
                alt="FreeStyle Libre Desk"
                src={logoFreestyle}
                className={"img-fluid d-inline w-100"}
              />
            </Col>
            <Col md={6}>
              <img
                alt="Abbott"
                src={logoAbbott}
                className={"img-fluid d-inline w-100"}
              />
            </Col>
            <Col md={12}>
              {" "}
              <hr
                style={{
                  color: "var(--bs-primary)",
                  margin: "20px auto",
                  borderWidth: "2px",
                }}
              />
            </Col>
          </Row>

          <Row>
            <Col className={"col-md-12 "}>
              <h4>FreeStyle Libre Desk</h4>
              {passwordExpiry && (
                <p className={"text-danger"}>La tua password è scaduta</p>
              )}
              <p>Cambia la tua password</p>
            </Col>

            <Col className={"col-md-12 "}>
              <Form>
                <Form.Group className={"position-relative"}>
                  <Form.Control
                    type="password"
                    id={"current_password"}
                    className={
                      "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white"
                    }
                    placeholder="Vecchia Password"
                    name={"current_password"}
                    required
                  />
                </Form.Group>

                <Form.Group className={"position-relative"}>
                  <Form.Control
                    type="password"
                    id={"new_password"}
                    onKeyUp={handlePasswordChange}
                    className={
                      "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white"
                    }
                    placeholder="Password"
                    name={"new_password"}
                    required
                  />
                </Form.Group>

                <Form.Group className={"position-relative"}>
                  <Form.Control
                    type="password"
                    id={"password_confirmation"}
                    className={
                      "my-2 bg-transparent border-top-0 border-0 border-bottom rounded-0 text-white"
                    }
                    placeholder="Conferma Password"
                    name={"password_confirmation"}
                    required
                  />
                </Form.Group>
                <br></br>
                <Row>
                  <Col></Col>
                  <Col>
                    <p
                      className={"text-white small text-start"}
                      style={{ textJustify: "distribute-all-lines" }}
                    >
                      *la password deve avere: <br></br>
                      <span id={"hasMinEightChars"}>
                        - almeno 8 caratteri
                      </span>{" "}
                      <br></br>
                      <span id={"hasUppercase"}>
                        - un carattere maiuscolo
                      </span>{" "}
                      <br></br>
                      <span id={"hasLowercaseAndNumber"}>
                        - uno minuscolo e un numero
                      </span>
                    </p>
                  </Col>
                  <Col></Col>
                </Row>

                <Button
                  variant="primary"
                  type="submit"
                  className={
                    "mt-3 rounded-5 px-5 text-uppercase text-black fw-bold"
                  }
                  onClick={submitHandler}
                >
                  Invia
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
        <Col></Col>
      </Row>

      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </Row>
  );
};

export default ResetPassword;
