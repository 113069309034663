import {Col, Form, Row} from "react-bootstrap";
import {AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineEdit} from "react-icons/ai";
import React, {useEffect, useState} from "react";
import {Role} from "../../../Utils/Role";
import {Link} from "react-router-dom";

const ActionsDetails = (props) => {
    const [role, setRole] = useState()

    useEffect(() => {
        const actualRole = Role();
        setRole(actualRole)
    }, [])

    return (
        <Row className={'rounded-3 w-100 fs-6 px-0 searchForm_container send_box mx-0 box-3 align-content-start'}>

            <Col className={'bkg-primary text-white text-start rounded-top-3 align-items-center'}>
                <Row>
                    <Col md={8}>
                        <p className={'mb-0 py-2'}>Azioni</p>
                    </Col>
                </Row>
            </Col>

            <Row className={'py-4 px-4'}>
                <Form.Group className={'my-2'}>
                    <Row className={'d-flex align-items-center'}>
                        <Form.Label className={'col-md-3'}>Stato</Form.Label>
                        <Col className={'col-md-9'}>
                            <Row className={'disabled rounded-3 py-2 w-100 d-flex justify-content-between'}>
                                <Col className={''} id={'status_name'}>{props.orderDetail && props.orderDetail.status_name}</Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className={'d-flex align-items-center my-5'}>
                        <Col className={'col-sm-12 my-2'}>
                            {!!role && !['data_entry', 'support'].includes(role) && props.orderDetail.status_id === 0 && (
                                <>
                                    <AiOutlineCloseCircle className={'text-danger fs-3'} onClick={props.handleStatusChange} data-value={2} data-id={props.orderDetail.id} data-patient-id={props.orderDetail.patient.id} />
                                    <Form.Label className={'mx-2'}>ANNULLA ORDINE</Form.Label>
                                </>
                            )}
                        </Col>
                        <Col className={'col-sm-12 my-2'}>
                            {!!role && !['data_entry', 'support'].includes(role) && props.orderDetail.status_id === 0 && (
                                <>
                                    <AiOutlineCheckCircle className={'text-success fs-3'} onClick={props.handleStatusChange} data-value={1} data-id={props.orderDetail.id} data-patient-id={props.orderDetail.patient.id} />
                                    <Form.Label className={'mx-2'}>APPROVA ORDINE</Form.Label>
                                </>
                            )}
                        </Col>
                        <Col className={'col-sm-12 my-2'}>
                            {!!role && !['support'].includes(role) && props.orderDetail.status_id === 0 && (
                               <>
                                    <Link to={'/modifica-ordine/' + props.orderDetail.id}>
                                        <AiOutlineEdit className={'text-primary-emphasis fs-3'} />
                                    </Link>
                                   <Form.Label className={'mx-2'}>MODIFICA ORDINE</Form.Label>
                                </>
                            )}
                        </Col>
                    </Row>

                </Form.Group>


            </Row>
        </Row>
    )
}

export default ActionsDetails