import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import "../../assets/css/Patients/Patients.css";
import {cityAutocomplete} from "../../Api/Geo";
import {emptyError, handleErrors} from "../Errors";
import {useNavigate} from "react-router-dom";
import {addPatient, editPatient, retrievePatientDetail,} from "../../Api/Patients/PatientsFetch";
import {TailSpin} from "react-loader-spinner";
import ShippingInfo from "./AddFormBox/ShippingInfo";
import ContactInfo from "./AddFormBox/ContactInfo";
import ResidenceInfo from "./AddFormBox/ResidenceInfo";
import {clearInputNameRandomString, generateRandomStringToAddInputName,} from "../../Utils/BrowserAutocompleteOff";
import {getSelectedIndex} from "../../Utils/fnSelect";
import {Role} from "../../Utils/Role";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";
import BlockedInfo from "./AddFormBox/BlockedInfo";

const AddPatientForm = ({ patientDetail, setPatientDetail, id }) => {
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
  const [cityValue, setCityValue] = useState("");
  const [city, setCity] = useState([]);
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [editMode, setEditMode] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isEdit, setIsEdit] = useState(false);
  const [role, setRole] = useState();
  const [isEditBlock, setIsEditBlock] = useState(false);
  const [unlocked, setUnlocked] = useState(0);

  useEffect(() => {
    const actualRole = Role();
    setRole(actualRole);

    setUnlocked(
      localStorage.hasOwnProperty("userData")
        ? JSON.parse(localStorage.getItem("userData")).can_unlock
        : 0,
    );
  }, []);

  useEffect(() => {
    if (cityValue.length >= 3) {
      cityAutocomplete(cityValue, setCity);
    }
  }, [cityValue]);

  useEffect(() => {
    setCity((prev) => {
      return prev;
    });
  }, [city]);

  useEffect(() => {
    if (id !== undefined && message !== undefined) {
      retrievePatientDetail({ setPatientDetail, id, setIsLoading });
    }
  }, [message]);

  useEffect(() => {
    // All'update, editMode diventa false e risetto tutto disabled
    if (patientDetail && editMode === true) {
      document
        .querySelector(".editMode")
        .forEach((element) => element.classList.remove("d-none"));
      document
        .querySelector(".reading")
        .forEach((element) => element.classList.add("d-none"));

      if (
        document.getElementById("btnAddOrder") &&
        document.getElementById("btnBlockPatient") &&
        document.querySelectorAll(".reading.d-none").length !== 0
      ) {
        document
          .getElementById("alert-text-before-edit")
          ?.classList.remove("d-none");
        document
          .getElementById("alert-text-before-edit-block")
          ?.classList.remove("d-none");
        document.getElementById("btnAddOrder").classList.add("disabled");
        document.getElementById("btnBlockPatient")?.classList.add("disabled");
      }
    } else if (patientDetail && editMode === false) {
      document
        .querySelectorAll(".editMode")
        .forEach((element) => element.classList.add("d-none"));
      document
        .querySelectorAll(".reading")
        .forEach((element) => element.classList.remove("d-none"));

      if (
        document.getElementById("btnAddOrder") &&
        document.getElementById("btnBlockPatient") &&
        document.querySelectorAll(".reading.d-none").length === 0
      ) {
        document.getElementById("btnAddOrder").classList.remove("disabled");
        document
          .getElementById("btnBlockPatient")
          ?.classList.remove("disabled");
        document
          .getElementById("alert-text-before-edit")
          ?.classList.add("d-none");
        document
          .getElementById("alert-text-before-edit-block")
          ?.classList.add("d-none");
      }

      const boxInput = document.querySelectorAll("input, select");
      for (let i of boxInput) {
        i.setAttribute("disabled", "disabled");
      }
    }
  }, [editMode, patientDetail]);

  useEffect(() => {
    if (errors) {
      console.log("errors:", errors);
      handleErrors(errors);
    }
  }, [errors]);

  //rimuove autocolmplete del browser
  useEffect(() => {
    for (let i of document.querySelectorAll("input")) {
      i.setAttribute("autoComplete", "new-password");
    }
  }, []);

  /*Funzione per modifica campi form */
  const handleEdit = (e) => {
    const dataID = e.currentTarget.parentNode
      .querySelector("svg")
      .getAttribute(
        "data-id",
      ); /* gestisco lo state con una nuova variabile per avere i cambiamenti che si vedono nello useEffect in tempo reale */
    const boxInput = document.querySelectorAll(
      ".box-" + dataID + " input, .box-" + dataID + " select",
    );

    if (document.querySelector(".editMode.box-" + dataID + ".d-none")) {
      document
        .querySelector(".editMode.box-" + dataID)
        .classList.remove("d-none");
      document.querySelector(".reading.box-" + dataID).classList.add("d-none");
      document
        .querySelector(".autocompleteGoogle.box-" + dataID)
        ?.classList.remove("d-none");

      if (
        document.getElementById("btnAddOrder") &&
        document.querySelectorAll(".reading.d-none").length !== 0
      ) {
        document
          .getElementById("alert-text-before-edit")
          ?.classList.remove("d-none");
        document
          .getElementById("alert-text-before-edit-block")
          ?.classList.remove("d-none");
        document.getElementById("btnAddOrder").classList.add("disabled");
        document.getElementById("btnBlockPatient")?.classList.add("disabled");
      }

      for (let i of boxInput) {
        i.removeAttribute("disabled");
      }

      setIsEdit(false);
    } else {
      document.querySelector(".editMode.box-" + dataID).classList.add("d-none");
      document
        .querySelector(".reading.box-" + dataID)
        .classList.remove("d-none");
      document
        .querySelector(".autocompleteGoogle.box-" + dataID)
        ?.classList.add("d-none");

      if (
        document.getElementById("btnAddOrder") &&
        document.querySelectorAll(".reading.d-none").length === 0
      ) {
        document.getElementById("btnAddOrder").classList.remove("disabled");
        document
          .getElementById("btnBlockPatient")
          ?.classList.remove("disabled");
        document
          .getElementById("alert-text-before-edit-block")
          ?.classList.add("d-none");
        document
          .getElementById("alert-text-before-edit")
          ?.classList.add("d-none");
      }

      for (let i of document.querySelectorAll("span.error_empty_field")) {
        i.remove();
      }

      setIsEdit(true);
      setErrors({});

      for (let i of boxInput) {
        //controllo se input o select
        i.tagName.toLowerCase() === "input"
          ? (i.value = i.getAttribute("value"))
          : (i.selectedIndex = getSelectedIndex(
              i,
              i.getAttribute("data-value"),
            ));

        i.setAttribute("disabled", "disabled");
      }
    }

    setIsEditBlock(false);
  };

  /*Submit form per AGGIUNTA*/
  const handleSubmit = (e) => {
    clearInputNameRandomString();
    /*Inserisco il numero di telefono negli input hidden per gli indirizzi*/
    for (let i of document.querySelectorAll("input.addressesPhone")) {
      i.value = document.querySelector("input[name=phone_number]").value;
    }
    const redirect = e.target.getAttribute("data-redirect");
    e.preventDefault();
    const form = document.getElementById("formAdd");
    emptyError();

    const formData = new FormData(form);

    const emptyfields = document.querySelector("span.error_empty_field");
    if (!emptyfields) {
      preventDoubleClick();
      addPatient({
        formData,
        setErrors,
        setIsLoading,
        setMessage,
        navigate,
        redirect,
      });
    }

    generateRandomStringToAddInputName();
  };

  /*Funzione modifica paziente*/
  const handleEditPatient = () => {
    clearInputNameRandomString();

    /*Inserisco il numero di telefono negli input hidden per gli indirizzi*/
    for (let i of document.querySelectorAll("input.addressesPhone")) {
      i.value = document.querySelector("input[name=phone_number]").value;
    }

    let formData = {};
    emptyError();

    //ciclo gli input per riprendere i valori
    document
      .querySelectorAll("#formAdd input, #formAdd select")
      .forEach(function (input, index) {
        let name = input.getAttribute("name");
        if (name !== "cf") formData[name] = input.value;
      });

    const emptyfields = document.querySelector("span.error_empty_field");
    if (!emptyfields) {
      preventDoubleClick();
      editPatient({
        id,
        formData,
        setErrors,
        setMessage,
        setEditMode,
        setIsLoading,
      });
    }
    generateRandomStringToAddInputName();
  };

  return (
    <>
      {/*{errors.map((i) => i)}*/}

      <Form id={"formAdd"} className={`formAdd`} autoComplete="off">
        <Row className={" my-5 text-start d-flex"}>
          {message && (
            <Alert
              variant={"success"}
              onClick={() => {
                setMessage(null);
              }}
              dismissible
            >
              {message}
            </Alert>
          )}
          {errors && typeof errors === "string" && (
            <Alert
              variant={"danger"}
              onClick={() => {
                setErrors(null);
              }}
              dismissible
            >
              {errors}
            </Alert>
          )}

          {/*Info e recapiti*/}
          <Col md={4} className={"px-1 align-content-stretch"}>
            <ContactInfo
              patientDetail={patientDetail}
              id={id}
              handleSavePatient={handleEditPatient}
              handleEdit={handleEdit}
              setErrors={setErrors}
              isEdit={isEdit}
            ></ContactInfo>
          </Col>
          {/*Indirizzo di residenza*/}
          <Col md={4} className={" px-1 d-flex align-content-stretch"}>
            <ResidenceInfo
              patientDetail={patientDetail}
              handleSavePatient={handleEditPatient}
              handleEdit={handleEdit}
              edit={isEdit}
              editmode={editMode}
              setErrors={setErrors}
            ></ResidenceInfo>
          </Col>
          {/*Indirizzo di spedizione*/}
          <Col md={4} className={" px-1 d-flex align-content-stretch"}>
            <ShippingInfo
              patientDetail={patientDetail}
              handleSavePatient={handleEditPatient}
              handleEdit={handleEdit}
              edit={isEdit}
              setErrors={setErrors}
            ></ShippingInfo>
          </Col>
          {/*User Log*/}
          <Col md={12} className={"px-1 my-2"}>
            <>
              <div className={"small text-end created text-secondary"}>
                {patientDetail?.user_logs?.created?.message}
              </div>
              <div className={"small text-end updated text-secondary"}>
                {patientDetail?.user_logs?.updated?.message}
              </div>
            </>
          </Col>
          {/* Informazioni di blocco*/}
          {!!role &&
            !["data_entry"].includes(role) &&
            ((patientDetail && patientDetail?.info?.suspension_date) ||
              isEditBlock) && (
              <Col md={12} className={"px-1 d-flex align-content-stretch mt-2"}>
                <BlockedInfo
                  patientDetail={patientDetail}
                  setErrors={setErrors}
                  setMessage={setMessage}
                  setIsLoading={setIsLoading}
                  setIsEditBlock={setIsEditBlock}
                  unlocked={unlocked}
                ></BlockedInfo>
              </Col>
            )}
          <p className={"mt-2"}>* Campi obbligatori</p>

          <Row className={"text-center justify-content-end d-flex px-0 mx-0"}>
            {patientDetail ? (
              <>
                {!!role &&
                  !["data_entry"].includes(role) &&
                  !patientDetail?.info?.suspension_date &&
                  !isEditBlock && (
                    <Col md={3}>
                      <span
                        className={"text-smaller d-none"}
                        id={"alert-text-before-edit-block"}
                      >
                        * Completare la modifica prima di poter bloccare un
                        paziente
                      </span>
                      <a
                        id="btnBlockPatient"
                        className={
                          "btn text-white mt-3 rounded-5 px-5 border-0 w-100 bg-danger text-uppercase"
                        }
                        onClick={() => setIsEditBlock(!isEditBlock)}
                      >
                        Blocca Paziente
                      </a>
                    </Col>
                  )}
                {!!role &&
                  !["support", "referente_aziendale"].includes(role) &&
                  !patientDetail?.info?.suspension_date &&
                  !isEditBlock && (
                    <Col md={3}>
                      <span
                        className={"text-smaller d-none"}
                        id={"alert-text-before-edit"}
                      >
                        * Completare la modifica prima di poter creare un nuovo
                        ordine
                      </span>
                      <Button
                        type="btn"
                        id="btnAddOrder"
                        href={"/inserimento-ordine/" + id}
                        className={
                          "mt-3 rounded-5 px-5 border-0 w-100 bg-success text-uppercase"
                        }
                      >
                        Nuovo ordine
                      </Button>
                    </Col>
                  )}
              </>
            ) : (
              <>
                <Col md={3}>
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    disabled={isButtonDisabled}
                    data-redirect={"/dettaglio-pazienti/"}
                    className={
                      "mt-3 rounded-5 px-5 border-0 w-100 bg-success text-uppercase"
                    }
                  >
                    Salva
                  </Button>
                </Col>

                {!!role &&
                  !["support", "referente_aziendale"].includes(role) && (
                    <Col md={3}>
                      <Button
                        variant="primary"
                        type="submit"
                        data-redirect={"/inserimento-ordine/"}
                        disabled={isButtonDisabled}
                        className={
                          "mt-3 rounded-5 px-5 border-0  w-100 text-uppercase"
                        }
                        onClick={handleSubmit}
                      >
                        Salva e crea un nuovo ordine
                      </Button>
                    </Col>
                  )}
              </>
            )}
          </Row>
        </Row>
      </Form>
      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};
export default AddPatientForm;
