import { Alert, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import React, { useEffect, useState } from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import SearchBillingFeeQuantity from "../../Components/Billing/SearchBillingFeeQuantity";
import {
  exportBillingQuantity,
  retrieveBillingQuantityList,
  saveQuantityList,
} from "../../Api/Billing/BillingFetch";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Components/Modal/ConfirmationModal";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const AddQuantityBilling = () => {
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [loading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [pageUrl, setPageUrl] = useState("/api/billing/v1/quantity");
  const [perPage, setPerPage] = useState("per_page=100");
  const [search, setSearch] = useState({});
  const [billingQuantityList, setBillingQuantityList] = useState({});
  const [filterSort, setFilterSort] = useState("");
  const [records, setTotalRecords] = useState();
  const [totalSensor, setTotalSensor] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [actionMessage, setActionMessage] = useState(
    "Sei sicuro di voler effettuare questa azione?",
  );
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
  const [invoiceSplitNumber, setInvoiceSplitNumber] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    generateRandomStringToAddInputName();
  }, []);

  useEffect(() => {
    if (Object.keys(search).length > 0) {
      retrieveBillingQuantityList({
        setBillingQuantityList,
        setIsLoading,
        setPagination,
        perPage,
        pageUrl,
        search,
        filterSort,
        setTotalRecords,
        setErrors,
        setTotalSensor,
        setInvoiceSplitNumber,
      });
    }
  }, [perPage, filterSort, pageUrl, search]);

  //export table
  const handleExport = (type) => {
    clearInputNameRandomString();

    exportBillingQuantity(search, type, { setIsLoading, setErrors });
    generateRandomStringToAddInputName();
  };

  //control close of modal
  const handleClose = () => {
    setShowModal(false);
  };

  //control show of modal and save event target
  const handleShow = () => {
    const split = document.getElementById("split_order_ref").checked ? 1 : 0;
    setShowModal(true);

    split === 1
      ? setActionMessage(
          "Verranno generate con split del rif. ordine un totale di: " +
            invoiceSplitNumber +
            " fattura/e. Continuare?",
        )
      : setActionMessage(
          "Verrà generata una fattura senza effettuare lo split per rif. ordine. Continuare?",
        );
  };

  //control action of modal
  const handleAction = (e) => {
    saveSearchResult(e);
    setShowModal(false);
  };

  //save result of search and generate a new billing
  const saveSearchResult = () => {
    let data = search;
    data["total"] = totalSensor;
    data["split_order_ref"] =
      document.getElementById("split_order_ref").checked;

    // Iterate through the keys of the object
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        // Check if the key contains "filters"
        if (key.includes("filters")) {
          // Extract the value associated with the current key
          const value = data[key]?.toString();
          // Replace the current key
          const newKey = key.replace("filters", "").replace(/\[|\]/g, "");
          // Add a new key-value pair with the new key and the extracted value
          data[newKey] = value;
          // Remove the old key
          delete data[key];
        }
      }
    }

    preventDoubleClick();
    saveQuantityList(data, {
      setErrors,
      setMessage,
      setIsLoading,
      setSearch,
      navigate,
    });
  };

  return (
    <>
      <Row className={"container-fluid w-100 mx-0 pt-5"}>
        <ConfirmationModal
          show={showModal}
          handleClose={handleClose}
          handleConfirm={handleAction}
          actionMessage={actionMessage}
          isButtonDisabled={isButtonDisabled}
        />
        <SearchBillingFeeQuantity
          setSearch={setSearch}
          title={"Quantità"}
          setPageUrl={setPageUrl}
        />

        {message && (
          <Alert
            variant={"success"}
            onClick={() => {
              setMessage(null);
            }}
            dismissible
          >
            {message}
          </Alert>
        )}
        {errors && typeof errors === "string" && (
          <Alert
            variant={"danger"}
            onClick={() => {
              setErrors(null);
            }}
            dismissible
          >
            {errors}
          </Alert>
        )}

        <CustomTable
          billingQuantityList={billingQuantityList}
          pagination={pagination}
          setPerPage={setPerPage}
          perPage={perPage}
          setPageUrl={setPageUrl}
          setFilterSort={setFilterSort}
          filterSort={filterSort}
          records={records}
          totalSensor={totalSensor}
          handleExport={handleExport}
          isButtonDisabled={isButtonDisabled}
          saveSearchResult={handleShow}
        />
      </Row>

      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default AddQuantityBilling;
