import {Alert, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import "../../assets/css/Patients/Patients.css";
import {handleErrors} from "../Errors";
import {TailSpin} from "react-loader-spinner";
import ActionsDetails from "./AddFormBox/ActionsDetails";
import GeneralInfo from "./AddFormBox/GeneralInfo";
import DurationInfo from "./AddFormBox/DurationInfo";
import PatientsInfo from "./AddFormBox/PatientsInfo";
import {updateNotes, updateRifOrder, updateStatus,} from "../../Api/Orders/OrdersFetch";
import {ConfirmationModal} from "../Modal/ConfirmationModal";
import {checkPendingOrder} from "../../Api/Patients/PatientsFetch";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const OrderDetailsForm = (props) => {
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [loading, setIsLoading] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [eventTarget, setEventTarget] = useState(null);
  const [actionMessage, setActionMessage] = useState(
    "Sei sicuro di voler effettuare questa azione?",
  );
  const [hasPendingOrder, setHasPendingOrder] = useState(null);
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
  const id = props.id;

  useEffect(() => {
    if (errors) {
      console.log("errors:", errors);
      handleErrors(errors);
    }
  }, [errors]);

  //rimuove autocolmplete del browser
  useEffect(() => {
    for (let i of document.querySelectorAll("input")) {
      i.setAttribute("autoComplete", "new-password");
    }
  }, []);

  /* Funzione per modifica campi form */
  useEffect(() => {
    setOrderDetail(props.orderDetail);
  }, [props.orderDetail]);

  //check if pending order is true
  useEffect(() => {
    if (hasPendingOrder !== null) {
      if (hasPendingOrder === true) {
        setActionMessage(
          "Questo paziente ha già un ordine in corso. Sicuro di voler approvare un nuovo ordine?",
        );
        setHasPendingOrder(null);
      } else {
        setActionMessage(`Sicuro di voler approvare l'ordine?`);
      }
    }
  }, [hasPendingOrder]);

  //control close of modal
  const handleClose = () => {
    setHasPendingOrder(null);
    setShowModal(false);
    setActionMessage("");
  };

  //control show of modal and save event target
  const handleShow = (event) => {
    setShowModal(true);
    setEventTarget(event.currentTarget);

    //setting message confirmation for modal
    if (event.currentTarget.getAttribute("data-value") === "1") {
      const patientID = event.currentTarget.getAttribute("data-patient-id");
      checkPendingOrder(patientID, { setHasPendingOrder, setIsLoading });
    } else {
      setActionMessage("Sicuro di voler rifiutare l' ordine?");
    }
  };

  //control action of modal
  const handleAction = async () => {
    if (eventTarget) {
      await handleStatusChange(eventTarget);
    }

    setShowModal(false);
  };

  // Function update status
  const handleStatusChange = async (e) => {
    preventDoubleClick();
    const id_status = e.getAttribute("data-value");
    const response = await updateStatus(
      id,
      id_status,
      setIsLoading,
      setMessage,
      setOrderDetail,
      setErrors,
    );
    if (response) {
      props.setOrderDetail(orderDetail);
      props.setUpdateView(true);
    }
    document
      .querySelector(".mainContent")
      .scrollIntoView({ behavior: "smooth" });
  };

  //update rif order (id_ordine is rif ordine)
  const handleRifOrderChange = (id_ordine) => {
    preventDoubleClick();
    updateRifOrder(id, id_ordine, { setIsLoading, setMessage, setErrors });

    setTimeout(() => {
      props.setUpdateView(true);
    }, 500);
  };

  const handleNotesChange = (note) => {
    preventDoubleClick();
    updateNotes(id, note, { setIsLoading, setMessage, setErrors });

    setTimeout(() => {
      props.setUpdateView(true);
    }, 500);
  };

  return (
    <>
      <ConfirmationModal
        show={showModal}
        handleClose={handleClose}
        handleConfirm={handleAction}
        actionMessage={actionMessage}
        isButtonDisabled={isButtonDisabled}
      />
      <Form id={"formAdd"} className={`formAdd`} autoComplete="off">
        <Row className={" my-3 text-start d-flex"}>
          {message && (
            <Alert
              variant={"success"}
              onClick={() => {
                setMessage(null);
              }}
              dismissible
            >
              {message}
            </Alert>
          )}
          {errors && typeof errors === "string" && (
            <Alert
              variant={"danger"}
              onClick={() => {
                setErrors(null);
              }}
              dismissible
            >
              {errors}
            </Alert>
          )}

          <Col md={12} className={"px-1 "}>
            <PatientsInfo orderDetail={orderDetail}></PatientsInfo>
          </Col>

          {/*Info e recapiti*/}
          <Col md={4} className={"px-1 d-flex align-content-stretch"}>
            <GeneralInfo
              orderDetail={orderDetail}
              handleRifOrderChange={handleRifOrderChange}
            ></GeneralInfo>
          </Col>
          {/*Indirizzo di residenza*/}
          <Col md={4} className={" px-1 d-flex align-content-stretch"}>
            <DurationInfo
              orderDetail={orderDetail}
              handleNotesChange={handleNotesChange}
            />
          </Col>
          {/*Indirizzo di spedizione*/}
          <Col md={4} className={" px-1 d-flex align-content-stretch"}>
            <ActionsDetails
              orderDetail={orderDetail}
              handleStatusChange={handleShow}
            ></ActionsDetails>
          </Col>

          <Col md={12} className={"px-1 my-2"}>
            <>
              <div className={"small text-end created text-secondary"}>
                {orderDetail?.user_logs?.created?.message}
              </div>
              <div className={"small text-end updated text-secondary"}>
                {orderDetail?.user_logs?.updated?.message}
              </div>
            </>
          </Col>
        </Row>
      </Form>
      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};
export default OrderDetailsForm;
