import { Col, Form, Row } from "react-bootstrap";
import IconTooltip from "../../Layout/IconsTooltip";
import {
  AiOutlineCheckCircle,
  AiOutlineCloseCircle,
  AiOutlineEdit,
} from "react-icons/ai";
import React, { useEffect, useState } from "react";
import { retrievePayerAndDistricts } from "../../../Api/Districts/DistrictsFetch";
import { Grouped } from "../../../Utils/Grouped";

const ContactInfo = (props) => {
  const [districtsData, setDistrictsData] = useState([]);
  const [payersData, setPayersData] = useState([]);
  const [districtId, setDistrictId] = useState("");
  const [payerId, setPayerId] = useState("");
  const [groupedPayers, setGroupedPayers] = useState();
  const setErrors = props.setErrors;

  //get payer
  useEffect(() => {
    retrievePayerAndDistricts(payerId, { setPayersData, setErrors }, props.id);
  }, []);

  //filter district by payer select
  useEffect(() => {
    if (payersData.length === 1) {
      setPayerId(payersData[0].payer_id);
    }
    setDistrictsData(
      payersData.filter((payer) => payer.payer_id === Number(payerId)),
    );
  }, [payerId, payersData]);

  //grouped payer
  useEffect(() => {
    setGroupedPayers(Grouped(payersData, "region"));
  }, [payersData]);

  //set payer id for filter district if exist
  useEffect(() => {
    setDistrictId(props.patientDetail ? props.patientDetail.district_id : "");
    setPayerId(props.patientDetail ? props.patientDetail.payer_id : null);
  }, [props.patientDetail, props.isEdit]);

  //check phone changing if have a digit not number
  const handlePhoneChange = (e) => {
    e.target.value = e.target.value.replace(/\D/g, ""); // Remove non-digits
  };

  //block digit non number oh input phone
  const handlePhoneKeyDown = (e) => {
    const allowedKeys = ["ArrowLeft", "ArrowRight", "Tab", "Backspace"];

    // Block non-numeric characters
    if (!allowedKeys.includes(e.key) && e.key && !/^\d$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <Row className={"rounded-3 fs-6 px-0 searchForm_container mx-0  box-1"}>
      <Col
        className={
          "bkg-primary text-white text-start rounded-top-3 align-items-center"
        }
      >
        <Row>
          <Col md={8}>
            <p className={"mb-0 py-2"}>Informazioni di contatto</p>
          </Col>
          <Col
            md={4}
            className={
              "d-flex justify-content-end align-items-center position-relative"
            }
          >
            {props.patientDetail && (
              <>
                <IconTooltip
                  action={props.handleEdit}
                  title="Modifica"
                  id="t-1"
                >
                  <span className={"reading box-1"}>
                    <AiOutlineEdit
                      className={"fs-3 text-second d-block"}
                      data-id={"1"}
                    ></AiOutlineEdit>
                  </span>
                </IconTooltip>

                <span className={"editMode box-1 d-none"}>
                  <AiOutlineCloseCircle
                    onClick={props.handleEdit}
                    className={"fs-3 text-danger d-inline mx-2"}
                    data-id={"1"}
                  ></AiOutlineCloseCircle>
                  <AiOutlineCheckCircle
                    onClick={props.handleSavePatient}
                    className={"fs-3 text-success d-inline"}
                    data-id={"1"}
                  ></AiOutlineCheckCircle>
                </span>
              </>
            )}
          </Col>
        </Row>
      </Col>

      <Row className={"py-4 px-4"}>
        <Form.Control
          type={"hidden"}
          name={"id"}
          value={props.id}
        ></Form.Control>
        <Form.Control
          type={"hidden"}
          className={"addressesPhone"}
          name={"addresses[0][mobile_phone]"}
        />
        <Form.Control
          type={"hidden"}
          className={"addressesPhone"}
          name={"addresses[1][mobile_phone]"}
        />

        {!props.patientDetail ? (
          <Form.Group className={"my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Payer*</Form.Label>
              <Col className={"col-md-9 "}>
                <Form.Select
                  name={"payer_id"}
                  className={"d-inline form-select-sm"}
                  value={payerId}
                  disabled={props.patientDetail && "disabled"}
                  data-value={
                    props.patientDetail && props.patientDetail.district_id
                  }
                  required
                  onChange={(e) => setPayerId(e.target.value)}
                >
                  <option value={""}>Seleziona</option>
                  {groupedPayers &&
                    Object.keys(groupedPayers).map((region) => {
                      return (
                        <optgroup key={region} label={region}>
                          {groupedPayers[region]?.map(
                            (
                              payer, // Using optional chaining to handle undefined or null
                            ) => (
                              <option
                                key={payer.payer_id}
                                value={payer.payer_id}
                              >
                                {payer.payer}
                              </option>
                            ),
                          )}
                        </optgroup>
                      );
                    })}
                </Form.Select>
              </Col>
            </Row>
          </Form.Group>
        ) : (
          <Form.Group className={"my-2"}>
            <Row>
              <Form.Label className={"col-md-3"}>Payer*</Form.Label>
              <Col className={"col-md-9 "}>
                <Form.Control
                  type="text"
                  name={"payer_title"}
                  readOnly={props.patientDetail ? "readonly" : ""}
                  defaultValue={
                    props.patientDetail ? props.patientDetail.payer_title : ""
                  }
                  disabled={props.patientDetail && "disabled"}
                  className={"d-inline form-control-sm"}
                  onChange={props.handleFormDetailChange}
                  required
                />
              </Col>
            </Row>
          </Form.Group>
        )}

        <Form.Group className={"my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Distretto*</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Select
                name="district_id"
                className={"d-inline form-select-sm"}
                value={districtId}
                disabled={props.patientDetail && "disabled"}
                data-value={
                  props.patientDetail && props.patientDetail.district_id
                }
                required
                onChange={(e) => setDistrictId(e.target.value)}
              >
                <option value={""}>Seleziona</option>
                {districtsData.map((payer) => {
                  return payer.districts.map((district) => {
                    return (
                      <option key={district.id} value={district.id}>
                        {district.title}
                      </option>
                    );
                  });
                })}
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        <Form.Group className={"my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Cognome*</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Control
                type="text"
                name={"last_name"}
                defaultValue={
                  props.patientDetail ? props.patientDetail.last_name : ""
                }
                className={"d-inline form-control-sm"}
                required
                disabled={props.patientDetail && "disabled"}
                onChange={props.handleFormDetailChange}
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className={" my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Nome*</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Control
                type="text"
                name={"first_name"}
                className={"d-inline form-control-sm"}
                defaultValue={
                  props.patientDetail ? props.patientDetail.first_name : ""
                }
                disabled={props.patientDetail && "disabled"}
                onChange={props.handleFormDetailChange}
                required
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className={"my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Codice Fiscale*</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Control
                type="text"
                name={"cf"}
                readOnly={props.patientDetail ? "readonly" : ""}
                defaultValue={props.patientDetail ? props.patientDetail.cf : ""}
                disabled={props.patientDetail && "disabled"}
                className={"d-inline form-control-sm text-uppercase"}
                onChange={props.handleFormDetailChange}
                required
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className={" my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Telefono*</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Control
                type="tel"
                pattern="[0-9]"
                name={"phone_number"}
                disabled={props.patientDetail && "disabled"}
                defaultValue={
                  props.patientDetail ? props.patientDetail.phone_number : ""
                }
                onKeyDown={handlePhoneKeyDown}
                onChange={handlePhoneChange}
                className={"d-inline form-control-sm "}
                required
              />
            </Col>
          </Row>
        </Form.Group>
        <Form.Group className={"my-2"}>
          <Row>
            <Form.Label className={"col-md-3"}>Email</Form.Label>
            <Col className={"col-md-9 "}>
              <Form.Control
                type="email"
                name={"email"}
                disabled={props.patientDetail && "disabled"}
                defaultValue={
                  props.patientDetail ? props.patientDetail.email : ""
                }
                onChange={props.handleFormDetailChange}
                className={"d-inline form-control-sm"}
              />
            </Col>
          </Row>
        </Form.Group>
      </Row>
    </Row>
  );
};

export default ContactInfo;
