import { Button, Col, Form, Row } from "react-bootstrap";
import React from "react";
import "../../assets/css/Patients/Patients.css";
import { BiEraser } from "react-icons/bi";
import IconTooltip from "../Layout/IconsTooltip";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";

const SearchPayersForm = (props) => {
  const clearInput = () => {
    props.setSearch({});
    for (let i of document.querySelectorAll("form input")) {
      i.value = "";
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    clearInputNameRandomString();
    props.setSearch({});
    props.setPageUrl("/api/payers/v1?page=1");

    for (let i of document.querySelectorAll("form select")) {
      if (i.value !== "") {
        const name = i.getAttribute("name");

        const value = i.value;

        props.setSearch((search) => ({
          ...search,
          [name]: value,
        }));
      }
    }
    generateRandomStringToAddInputName();
  };

  return (
    <>
      <Row className={"searchForm_container rounded-3 fs-6 px-0 mx-0"}>
        <Col
          className={
            "bkg-primary text-white text-start rounded-top-3 align-items-center"
          }
        >
          <Row>
            <Col md={8}>
              <p className={"mb-0 py-2"}>Filtra Payers</p>
            </Col>
            <Col
              md={4}
              className={
                "d-flex justify-content-end align-items-center position-relative"
              }
            >
              <IconTooltip title="Pulisci Campi" id="t-1" action={clearInput}>
                <BiEraser className={"fs-3 text-second d-block"}></BiEraser>
              </IconTooltip>
            </Col>
          </Row>
        </Col>

        <Form className={"row py-3 text-start"} id={"searchForm"}>
          {!!props.role &&
            !["data_entry", "single_user", "referente_aziendale"].includes(
              props.role,
            ) && (
              <Form.Group className={"col-md-4 my-2"}>
                <Row>
                  <Form.Label className={"col-md-3"}>Regione</Form.Label>
                  <Col className={"col-md-8 "}>
                    <Form.Select
                      name={"filters[region]"}
                      className={"d-inline form-control-sm"}
                    >
                      <option value={""}>Tutte</option>
                      <option value={"Lazio"}>Lazio</option>
                      <option value={"Lombardia"}>Lombardia</option>
                      <option value={"Marche"}>Marche</option>
                      <option value={"Sicilia"}>Sicilia</option>
                      <option value={"Piemonte"}>Piemonte</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Form.Group>
            )}

          <Row className={"text-center justify-content-center"}>
            <Col className={"col-md-3"}>
              <Button
                variant="primary"
                onClick={handleSearch}
                type="submit"
                className={"mt-3 rounded-5 px-5  text-uppercase"}
              >
                Cerca
              </Button>
            </Col>
          </Row>
        </Form>
      </Row>
    </>
  );
};
export default SearchPayersForm;
