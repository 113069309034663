//download massive Pods
import axiosInstance from "../AxiosInstance";

//download pod with external link
export const downloadPods = (formData, {setIsLoading, setErrors, setMessage}) => {
    setIsLoading(true)

    axiosInstance.post('/api/reports/v1/downloadPods/redirect',
        formData
    ).then((response) => {
        window.location.href = response.data.data.url;
        setMessage(response.data.message)
        setIsLoading(false)
    }).catch((error) => {
        //  setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            setErrors(error.response.data.errors)
            setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            console.log(error)
            // Handle other errors (e.g., network issues)
            setErrors(error.response.data.message)
            setIsLoading(false)
            console.error('Error:', error);
        }
    });
};