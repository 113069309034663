import React, { useEffect, useState } from "react";
import { Alert, Row } from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import { TailSpin } from "react-loader-spinner";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import SearchPayersForm from "../../Components/Payers/SearchForm";
import { retrievePayers } from "../../Api/Payer/PayersFetch";
import { Role } from "../../Utils/Role";

const PayersList = () => {
  const [payersData, setPayersData] = useState({});
  const [pagination, setPagination] = useState({});
  const [pageUrl, setPageUrl] = useState("/api/payers/v1");
  const [perPage, setPerPage] = useState("per_page=10");
  const [search, setSearch] = useState({});
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [loading, setIsLoading] = useState(false);
  const [filterSort, setFilterSort] = useState("");
  const [records, setTotalRecords] = useState();
  const [role, setRole] = useState();

  useEffect(() => {
    const actualRole = Role();
    setRole(actualRole);

    if (
      actualRole &&
      !["data_entry", "single_user", "referente_aziendale"].includes(actualRole)
    ) {
      retrievePayers({
        setPayersData,
        setErrors,
        pageUrl,
        perPage,
        search,
        filterSort,
      });
    }
  }, [search]);

  useEffect(() => {
    generateRandomStringToAddInputName();
  }, []);

  //export table
  const handleExportTable = () => {
    clearInputNameRandomString();
    // exportOrderList(formData, {setIsLoading, setErrors})

    generateRandomStringToAddInputName();
  };

  // Function update status

  return (
    <>
      <Row className={"container-fluid w-100 mx-0 pt-5"}>
        {message && (
          <Alert
            variant={"success"}
            onClick={() => {
              setMessage(null);
            }}
            dismissible
          >
            {message}
          </Alert>
        )}
        {errors && (
          <Alert
            variant={"danger"}
            onClick={() => {
              setErrors(null);
            }}
            dismissible
          >
            {errors}
          </Alert>
        )}

        <SearchPayersForm
          setSearch={setSearch}
          search={search}
          setPageUrl={setPageUrl}
          role={role}
        ></SearchPayersForm>
        <CustomTable
          payersList={payersData}
          pagination={pagination}
          setPerPage={setPerPage}
          perPage={perPage}
          setPageUrl={setPageUrl}
          setFilterSort={setFilterSort}
          filterSort={filterSort}
          records={records}
          handleExportTable={handleExportTable}
        ></CustomTable>
      </Row>
      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default PayersList;
