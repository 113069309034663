import React, {useEffect, useState} from "react";
import CustomTable from "../../Components/Table/CustomTable";
import {Alert, Row} from "react-bootstrap";
import {clearInputNameRandomString, generateRandomStringToAddInputName} from "../../Utils/BrowserAutocompleteOff";
import {exportExpiringPatients, retrieveExpiringPatients} from "../../Api/Reports/ReportsFetch";
import {TailSpin} from "react-loader-spinner";
import SearchExpirationPatients from "../../Components/Reports/SearchExpirationPatients";

const ExpiringPatients = () => {
    const [expiringPatients, setExpiringPatients] = useState([])
    const [pageUrl, setPageUrl] = useState('/api/reports/v1/expiringPatients');
    const [search, setSearch] = useState();
    const [perPage, setPerPage] = useState('per_page=10')
    const [loading, setIsLoading] = useState();
    const [filterSort, setFilterSort] = useState('');
    const [totalRecords, setTotalRecords] = useState();
    const [pagination, setPagination] = useState();
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()

    useEffect(() => {
        retrieveExpiringPatients(({setExpiringPatients, pageUrl, search, perPage, filterSort, setIsLoading, setTotalRecords, setPagination}))
    }, [perPage, filterSort, pageUrl, search])

    //export reports in excel
    const handleExportTable = (e) => {
        clearInputNameRandomString()
        const payerId = document.getElementById('payerId')?.value;
        exportExpiringPatients(payerId, {setErrors, setIsLoading})
        generateRandomStringToAddInputName()
    }


    return (
        <>
            <Row className={'text-start mt-4'}>
                <h2>Pazienti in scadenza</h2>
            </Row>

            <SearchExpirationPatients setSearch={setSearch} search={search} setPageUrl={setPageUrl}></SearchExpirationPatients>

            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}

            <CustomTable expiringPatients={expiringPatients} pagination={pagination}
                         setPerPage={setPerPage} records={totalRecords}
                         perPage={perPage} setPageUrl={setPageUrl} setFilterSort={setFilterSort}
                         filterSort={filterSort} handleExportTable={handleExportTable} />

            {loading &&
                <TailSpin
                    height="80"
                    width="80"
                    color="var(--primary)"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
                    visible={true}/>
            }
        </>
    )
}

export default ExpiringPatients