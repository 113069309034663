import React, { useEffect, useState } from "react";
import {
  exportOrderList,
  retrieveOrdersList,
  updateStatus,
} from "../../Api/Orders/OrdersFetch";
import { Alert, Row } from "react-bootstrap";
import CustomTable from "../../Components/Table/CustomTable";
import { TailSpin } from "react-loader-spinner";
import SearchOrderssForm from "../../Components/Orders/SearchForm";
import {
  clearInputNameRandomString,
  generateRandomStringToAddInputName,
} from "../../Utils/BrowserAutocompleteOff";
import { ConfirmationModal } from "../../Components/Modal/ConfirmationModal";
import { checkPendingOrder } from "../../Api/Patients/PatientsFetch";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";

const OrdersList = () => {
  const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
  const [ordersList, setOrdersList] = useState();
  const [pagination, setPagination] = useState({});
  const [pageUrl, setPageUrl] = useState("/api/orders/v1");
  const [perPage, setPerPage] = useState("per_page=10");
  const [search, setSearch] = useState({});
  const [errors, setErrors] = useState();
  const [message, setMessage] = useState();
  const [loading, setIsLoading] = useState(true);
  const [filterSort, setFilterSort] = useState("");
  const [records, setTotalRecords] = useState();
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eventTarget, setEventTarget] = useState(null);
  const [actionMessage, setActionMessage] = useState("");
  const [hasPendingOrder, setHasPendingOrder] = useState(null);

  useEffect(() => {
    retrieveOrdersList({
      setOrdersList,
      setIsLoading,
      setPagination,
      perPage,
      pageUrl,
      search,
      filterSort,
      setTotalRecords,
      setErrors,
    });
    setStatusUpdated(false);
  }, [perPage, filterSort, pageUrl, search, statusUpdated]);

  useEffect(() => {
    generateRandomStringToAddInputName();
  }, []);

  useEffect(() => {
    if (hasPendingOrder !== null) {
      if (hasPendingOrder === true) {
        setActionMessage(
          "Questo paziente ha già un ordine in corso. Sicuro di voler approvare un nuovo ordine?",
        );
        setHasPendingOrder(null);
      } else {
        setActionMessage("Sicuro di voler approvare l' ordine?");
      }
    }
  }, [hasPendingOrder]);

  //export table
  const handleExportTable = () => {
    clearInputNameRandomString();
    const form = document.getElementById("searchForm");
    const formData = new FormData(form);

    exportOrderList(formData, { setIsLoading, setErrors, setMessage });
    generateRandomStringToAddInputName();
  };

  //control close of modal
  const handleClose = () => {
    setHasPendingOrder(null);
    setShowModal(false);
    setActionMessage("");
  };

  //control show of modal and save event target
  const handleShow = async (event) => {
    const eventTarget = event.currentTarget;
    await setShowModal(true);
    await setEventTarget(eventTarget);
    const dataValue = await eventTarget.getAttribute("data-value");
    //setting message confirmation for modal
    if (dataValue === String(1)) {
      const patientID = await eventTarget.getAttribute("data-patient-id");
      await checkPendingOrder(patientID, {
        setHasPendingOrder,
        setIsLoading,
      });
    } else {
      await setActionMessage(`Sicuro di voler rifiutare l'ordine?`);
    }
  };

  //control action of modal
  const handleAction = async () => {
    if (eventTarget) {
      await handleStatusChange(eventTarget);
    }

    await setShowModal(false);
  };

  // Function update status
  const handleStatusChange = async (e) => {
    const id_status = await e.getAttribute("data-value");
    const id = await e.getAttribute("data-id");
    await preventDoubleClick();
    const responseUpdateStatus = await updateStatus(
      id,
      id_status,
      setIsLoading,
      setMessage,
      null,
      setErrors,
    );
    if (responseUpdateStatus) {
      setStatusUpdated(true);
    }
  };

  return (
    <>
      <Row className={"container-fluid w-100 mx-0 pt-5"}>
        <SearchOrderssForm
          setSearch={setSearch}
          search={search}
          setPageUrl={setPageUrl}
        ></SearchOrderssForm>
        <ConfirmationModal
          show={showModal}
          handleClose={handleClose}
          handleConfirm={handleAction}
          actionMessage={actionMessage}
          isButtonDisabled={isButtonDisabled}
        />

        {message && (
          <Alert
            className={"mt-4"}
            variant={"success"}
            onClick={() => {
              setMessage(null);
            }}
            dismissible
          >
            {" "}
            {message}{" "}
          </Alert>
        )}
        {errors && (
          <Alert
            className={"mt-4"}
            variant={"danger"}
            onClick={() => {
              setErrors(null);
            }}
            dismissible
          >
            {" "}
            {errors}{" "}
          </Alert>
        )}

        <CustomTable
          ordersList={ordersList}
          pagination={pagination}
          setPerPage={setPerPage}
          perPage={perPage}
          setPageUrl={setPageUrl}
          setFilterSort={setFilterSort}
          filterSort={filterSort}
          records={records}
          handleExportTable={handleExportTable}
          handleStatusChange={handleShow}
        ></CustomTable>
      </Row>
      {loading && (
        <TailSpin
          height="80"
          width="80"
          color="var(--primary)"
          ariaLabel="tail-spin-loading"
          radius="1"
          wrapperStyle={{}}
          wrapperClass="position-fixed w-100 h-100 align-items-center justify-content-center d-flex spinnerPosition"
          visible={true}
        />
      )}
    </>
  );
};

export default OrdersList;
